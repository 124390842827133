import React, { useEffect } from "react";
import { ReactComponent as IconCategories } from "../../assets/images/header_icons/categories.svg";
import { ReactComponent as IconCategoriesActive } from "../../assets/images/header_icons/categories-active.svg";
import { ReactComponent as IconMapMarker } from "../../assets/images/header_icons/nav-marker.svg";
import { ReactComponent as IconMapMarkerActive } from "../../assets/images/header_icons/nav-marker-active.svg";
import { ReactComponent as IconHome } from "../../assets/images/header_icons/home-outline.svg";
import { ReactComponent as IconHomeActive } from "../../assets/images/header_icons/home.svg";
import { ReactComponent as LogoMG } from "../../assets/images/header_icons/logo.svg";
import { ReactComponent as IconList } from "../../assets/images/header_icons/list-left.svg";
import { ReactComponent as IconListActive } from "../../assets/images/header_icons/list-left-active.svg";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { useState } from "react";
import { Store } from "../../Store";
import { useContext } from "react";
import { ButtonBase } from "@material-ui/core";

const NavButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

const IconContainer = styled.div`
	margin-bottom: 5px;
	transition: transform 0.3s ease-in-out;
`;

const NavButton = styled(ButtonBase)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	font-family: var(--akkurat);
	font-size: 14px;
	font-weight: 400;
	line-height: 16.8px;
	color: var(--Museumssvart);
	cursor: pointer;
	user-select: none;
	padding: 10px;
	transition: color 0.3 ease-in-out;
	text-transform: none;
	//height: 80px;
	&[data-currentpage="true"] {
		font-weight: 700;
	}

	&:hover {
		color: var(--blaze500);
	}

	&:hover ${IconContainer} {
		transform: scale(1.3) translateY(-2px);
	}

	&:focus {
		background-color: rgba(0, 0, 0, 0.1);
		border-radius: 8px;
	}
`;

const icons = {
	categories: {
		active: <IconCategoriesActive />,
		inactive: <IconCategories />,
	},
	map: { active: <IconMapMarkerActive />, inactive: <IconMapMarker /> },
	home: { active: <IconHomeActive />, inactive: <IconHome /> },
	counties: { active: <IconListActive />, inactive: <IconList /> },
};

const NavButtonGroup = ({}) => {
	const { state, dispatch } = useContext(Store);
	const history = useHistory();
	const { t } = useTranslation("common");
	/*
	console.log("\nhistory.location.pathname: ", history.location.pathname);
	console.log("window.history: ", window.history);
	console.log("\n\n\n\n\nhistory: ", history);
	*/
	const [currentPath, setCurrentPath] = useState(history.location.pathname);
	const currentPathRef = useRef(currentPath);
	const setCurrentPathRef = useRef(setCurrentPath);

	useEffect(() => {
		/*console.log("history ref:", currentPathRef);
		console.log("useffect hist: ", history.location.pathname);*/
		if (state.user.currentPage === currentPathRef.current) return;
		/*console.log(
			"\n\n\n\nhistory.location.pathname: ",
			history.location.pathname,
		);*/
	}, [state.user.currentPage]);

	return (
		<NavButtonContainer>
			{[
				[t("general.home"), "/", "home"],
				[t("general.map"), "/museums", "map"],
				[t("general.categories"), "/categories", "categories"],
				[t("general.counties"), "/counties", "counties"],
			].map((text, index) => (
				<NavButton
					key={text[2]}
					onClick={() => history.push(text[1])}
					data-currentpage={text[1] === history.location.pathname}
					tabIndex={0}
				>
					<IconContainer>
						{
							icons[text[2]][
								text[1] === history.location.pathname ? "active" : "inactive"
							]
						}
					</IconContainer>
					{text[0]}
				</NavButton>
			))}
		</NavButtonContainer>
	);
};
export default NavButtonGroup;
