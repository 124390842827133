import React, { useCallback } from "react";
import styled from "styled-components";
import GpsFixedRoundedIcon from "@material-ui/icons/GpsFixedRounded";
import { Button } from "@material-ui/core";
import Triangle from "./triangle";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { Store } from "../../Store";
import { useHistory } from "react-router";

const Container = styled.div`
	position: absolute;
	bottom: -80px;
	left: 0;
	height: 72px;
	background-color: #fff;
	border-radius: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 32px 0 24px;
`;

const ExploreNearbyButton = styled(Button)`
	text-transform: none;
	cursor: pointer;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
`;

const GpsCustomIcon = styled(GpsFixedRoundedIcon)`
	color: var(--blaze500);
	margin-right: 8.67px;
`;

const TriangleContainer = styled.div`
	position: absolute;
	top: -8px;
	left: 60px;
`;

const ExploreMuseumsNearbyBubble = ({}) => {
	const { t } = useTranslation("common");

	const { dispatch } = useContext(Store);
	const history = useHistory();
	//console.log("selectorState: ", selectorState);
	//console.log("searchBarOverlayOpen: ", searchBarOverlayOpen);

	const handleNearBy =  (evt) => {
		console.log("evt");
		console.dir(evt);
		dispatch({
			type: "SET_SETTINGS_SEARCHNEARBY",
			payload: true,
		});
		history.push({
			pathname: "/museums",
			//search: `lat=${state.user.lat}&lng=${state.user.lng}`,
			//position: { lat: state.user.lat, lng: state.user.lng },
		});
	}
	return (
		<Container key={"exploreMuseumsNearbyBubble"}>
			<TriangleContainer>
				<Triangle />
			</TriangleContainer>

			<ExploreNearbyButton				
				onClick={handleNearBy}
				startIcon={<GpsCustomIcon />}
			>
				{t("searchBar.searchNearby")}
			</ExploreNearbyButton>
		</Container>
	);
};
export default ExploreMuseumsNearbyBubble;
