import getDistance from "geolib/es/getDistance";
import * as R from "ramda";

/**
 * Default search distance, 50,000m => 50km
 */
const DefaultSearchDistance = 50000;

// DEPRECATED
export const getMuseumDistance = ({ currentLocation, museumsList = [] }) => {
	// skal museer uten posisjon filtreres bort?
	const museumsWithLocation = museumsList.filter(m => m.position.lat);
	const lat = R.prop("lat", currentLocation);
	const lng = R.prop("lng", currentLocation);
	if (R.isNil(lat) || R.isNil(lng)) {
		museumsWithLocation.sort((a, b) => {
			return a.name.no - b.distance; // ?????
		});
		return museumsWithLocation;
	} else {
		const userCords = {
			latitude: lat,
			longitude: lng,
		};

		const museumsWithDistance = museumsWithLocation
			.filter(m => m.position.lat)
			.map(m => {
				const latitude = m.position.lat;
				const longitude = m.position.lng;

				const distance = getDistance(userCords, { latitude, longitude });
				return R.assocPath(["distance"], distance, m);
			});

		return museumsWithDistance;
	}
};

export const getMuseumsInRange = (currentLocation, museumsList = []) => {
	const museumsWithDistance = museumsList; //getMuseumDistance({currentLocation, museumsList});

	let nearbyMuseums = museumsWithDistance.filter(
		m => R.prop("distance", m) < DefaultSearchDistance,
	);

	// If there are no museums within 50 km range, increase the radius until there are at least 1 museum within range.
	for (let i = 2; i < 12; i++) {
		if (nearbyMuseums.length) {
			break;
		}

		nearbyMuseums = museumsWithDistance.filter(
			m => R.prop("distance", m) < DefaultSearchDistance * i,
		);
	}

	nearbyMuseums.sort((a, b) => {
		return R.prop("distance", a) - R.prop("distance", b);
	});
	// console.log("getNearestHelper.nearbyMuseums"); //DEBUG
	return nearbyMuseums;
};
