import { createMuiTheme } from '@material-ui/core/styles';

const roboto = {
  fontFamily: "'Roboto', sans-serif",
}

const akkurat = {
  fontFamily: "'AkkuratLLWeb', sans-serif",
}

export const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#c62828',
    },
    secondary: {
      main: "#C0392B",
    },
    action: {
      disabledBackground: 'rgba(224, 224, 224, 0.8)',
    },
  },
  typography: {
    h1: akkurat,
    h2: akkurat,
    h3: akkurat,
    h4: akkurat,
    h5: akkurat,
    h6: akkurat,
  },
});

const color1 = '#f5f5f5';
const color2 = '#616161';
const color3 = '#eeeeee';
const color4 = '#757575';
const color5 = '#e5e5e5';
const color6 = '#9e9e9e';

// change google maps to silver theme
export const mapStyle = [
  {
    elementType: 'geometry',
    stylers: [{ color: color1 }],
  },
  {
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{ color: color2 }],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{ color: color1 }],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#bdbdbd' }],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{ color: color3 }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: color4 }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: color5 }],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: color6 }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [{ color: color4 }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#dadada' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: color2 }],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [{ color: color6 }],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [{ color: color5 }],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [{ color: color3 }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#c9c9c9' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: color6 }],
  },
];

export default {
  colors: {},

  header: {
    height: 70,
    background: '#AAAAAA',
    hoverBackground: 'rgba(245, 247, 249, 0.6)',
    color: '#5D5656',
    activeColor: '#362727',
  },

  footer: {
    height: 50,
    background: 'rgba(255, 255, 255, 0.8)',
    color: '#ECEFF1',
    hoverColor: '#c62828',
  },
};
