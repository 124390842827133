import { Settings } from '../../config/Settings';

export class Api {
  HOST = Settings.KULTURNAV_API_URL;
  get = async path => {
    return await fetch(this.HOST + path).then(res => {
      return res.json();
    })
      .catch(err => {
        console.log("KnService error:", err);
      })
  }

}
