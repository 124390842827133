import MyLocationIcon from '@material-ui/icons/MyLocation';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useContext } from 'react';
import { Store } from '../../Store';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';


const useStyles = makeStyles({
  locationButton: {
    width: props => (props.isWide ? 40 : 50),
    height: props => (props.isWide ? 40 : 50),
    backgroundColor: props => props.buttonActive ? '#fff' : "lightgray",
    position: 'absolute',
    zIndex: 99,
    bottom: props => (props.isWide ? 110 : 25),
    right: 10,
    borderRadius: props => (props.isWide ? 3 : 25),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '1px 1px 7px -1px rgba(0,0,0,0.25)',
    cursor: 'pointer',
  },
});

const LocationButton = ({
  isWide,
  handleCurrentLocation,
  isOnCurrentPosition,
  setIsOnCurrentPosition,
  setSearchTerm,
}) => {
  
  const { state, dispatch } = useContext(Store);
  const [buttonActive, setButtonActive] = useState(true);
  const setButtonActiveRef = useRef(setButtonActive);
  const classes = useStyles({ isWide, buttonActive });
  const handleClick = () => {
    if (!state.settings.searchNearby && buttonActive) {
      setButtonActive(false);
      setIsOnCurrentPosition(true);
      dispatch({
        type: "SET_SETTINGS_SEARCHNEARBY",
        payload: true
      });
      //handleCurrentLocation();
      setSearchTerm('');
    }
  };

  useEffect(()=>{
    let timeout;
    if(!buttonActive){
      timeout = setTimeout(()=>{setButtonActiveRef.current(true)}, 10000);
    }
    return ()=>{clearTimeout(timeout);}
    
  },[buttonActive])

  return (
    <div className={classes.locationButton} onClick={handleClick}>
      <MyLocationIcon
        style={{ color: isOnCurrentPosition ? '#538cdd' : '#666' }}
      />
    </div>
  );
};

export default LocationButton;
