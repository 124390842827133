import React, { useEffect, useRef, useState } from "react";
import { Button, IconButton, makeStyle, makeStyles } from "@material-ui/core";
import { DmService } from "../../api/DigitaltMuseum/DmService";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { none } from "ramda";
import ImgLoader from "../ImgLoader/imgLoader";



/*

    http://api.dimu.org/api/solr/select?q=identifier.owner:(HH)&fq=artifact.hasPictures:true&start=2&wt=json&api.key=demo
    owner(MUSEUMID)
    start: startindex

*/


const useStyles = makeStyles({


    carouselInnerContainer: {
        boxSizing: "border-box",
        display: "flex",
        width: props => `${(props.numImages * (props.imageWidth + props.imageMargin)) - props.imageMargin}px`,
        height: "fit-content",
        overflowX: "scroll",
        overflowY: "hidden",
        scrollSnapType: "x mandatory",
        scrollPaddingTop: "15vh",
        scrollBehavior: "smooth",

        "-webkit-overflow-scrolling": "touch",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        }
    },

    imgThumbnailContainer: {
        alignItems: "flex-start",
        scrollSnapAlign: "start",
        "& + $imgThumbnailContainer": {
            marginLeft: props => `${props.imageMargin}px`,
        }

    },    

    buttonRow: {
        display: "flex",
        justifyContent: "space-between",
    },
});

const Carousel = ({
    dimuId,
    imageWidth = 275,
    imageHeight = 150,
    imageMargin = 20,
    numImages = 4,
    scrollElements = 4 // how many elements to scroll
}) => {
    const classes = useStyles({ imageWidth: imageWidth, imageHeight: imageHeight, imageMargin: imageMargin, numImages: numImages });
    
    const [images, setImages] = useState([]);
    const scrollContainer = useRef(null);
    const [scrollBtnDisabled, setScrollBtnDisabled] = useState({ left: true, right: false });

    const scrollNext = () => {
        scrollContainer.current.scrollBy((imageWidth + imageMargin) * scrollElements, 0);
    }

    const scrollPrev = () => {
        scrollContainer.current.scrollBy(-(imageWidth + imageMargin) * scrollElements, 0);
    }

    const handleScroll = () => {
        const scrollPos = scrollContainer.current.scrollLeft;
        const scrollWidth = scrollContainer.current.scrollWidth;
        const scrollOffsetWidth = scrollContainer.current.offsetWidth;

        if (scrollPos === 0) {
            setScrollBtnDisabled({ left: true, right: false });
        }
        else if (scrollPos + scrollOffsetWidth >= scrollWidth) {
            setScrollBtnDisabled({ left: false, right: true });
        }
        else {
            setScrollBtnDisabled({ left: false, right: false });
        }
    }


    

    useEffect(() => {
        const dimuApi = new DmService();
        const getData = async () => {
            const data = await dimuApi.getListOfImages(dimuId, 0, 50);
            console.log(data);
            setImages(data);
            
        }
        getData()

    }, [dimuId])


    return (
        <div>
            <div className={classes.carouselInnerContainer}
                ref={scrollContainer}
                onScroll={handleScroll}>
                {images.length > 0 &&
                    images.map(imgEl =>
                        <div
                            className={classes.imgThumbnailContainer}
                            key={imgEl.imageId + "_" + imgEl.uniqueId + "_" + imgEl.mediaId}>
                            <a href={imgEl.dimuUrl}
                                target="_blank">
                                {/*<img
                                    src={`${imgEl.url}?dimension=57x57-crop`}
                                    alt={imgEl.title}
                                    className={classes.imgThumbnail}
                                ></img>*/}
                                <ImgLoader
                                    key={imgEl.mediaId}
                                    url={imgEl.url}
                                    altTxt={imgEl.title}
                                    lowres={"57x57-crop"}
                                    hires={"400x400"}
                                    imageHeight={imageHeight}
                                    imageWidth={imageWidth}
                                />
                            </a>
                        </div>
                    )}
            </div>
            <div className={classes.buttonRow}>
                <IconButton
                    disabled={scrollBtnDisabled.left}
                    onClick={scrollPrev}>
                    <ArrowBack />
                </IconButton>

                <IconButton
                    disabled={scrollBtnDisabled.right}
                    onClick={scrollNext}>
                    <ArrowForward />
                </IconButton>
            </div>
        </div>
    );
}

export default Carousel;