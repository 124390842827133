
import React, { useContext, useState } from "react";
import { Button, ClickAwayListener, Collapse, Fade, makeStyles } from "@material-ui/core";
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { useTranslation } from 'react-i18next';
import ListCategories from "../ListCategories/listCategories";
import ListCounties from "../ListCounties/listCounties";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Store } from "../../Store";
import FilterHint from "../FilterHint/filterhint";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    searchParametersContainer: {
        width: "100%",

    },
    filterMainContainer: {
        display: "flex",
        flexWrap: "nowrap",
        marginRight: "20px",
    },
    filterItemContainer: {
        "& + $filterItemContainer": {
            marginLeft: "10px",
        },
    },
    buttonContainer: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",        
    },
    filterButton: {
        color: "#FFF",
        textTransform: "none",
        fontSize: props => props.isWide ? "16px" : "14px",
        fontWeight: "500",
        lineHeight: "21px",
        "&::first-letter": {
            textTransform: "uppercase",
        },
    },
    arrowIcon: {
        width: "25px",
        height: "25px",
    },
    selectedFilter: {
        textDecoration: "underline",

        "&:hover": {
            textDecoration: "underline",
        }
    },
    buttonLocation: {
        color: "#FFF",
        fontWeight: 700,
        fontSize: props => props.isWide ? "16px" : "14px",
        lineHeight: "21px",
        textShadow: "0px 0px 5px #00000080",
        textTransform: "none",
        marginLeft: "6px",
    },
    myLocationIcon: {
        filter: "drop-shadow(0px 0px 10px #00000080)",
        "--locationIconSize": props => (props.isWide && "20px") || "14px",
        height: 'var(--locationIconSize)',
        width: 'var(--locationIconSize)',
        marginBottom: '1px'
    },
    filterListContainer: {
        zIndex: "1",
        position: "absolute",
        display: "flex",
        justifyContent: "flex-start",
        backgroundColor: "#FFF",
        borderRadius: "5px",        
        padding: props => props.isWide ? "0px 31px 23px 36px" : "0px 21px 23px 26px",
        marginTop: "10px",
        marginLeft: "-6px",
        maxWidth: "min-content",
        /* Triangle */
        "&::before": {
            position: "absolute",
            content: "''",
            width: 0,
            height: 0,
            borderLeft: "15px solid transparent",
            borderRight: "15px solid transparent",
            borderBottom: "18px solid #FFF",
            top: "-10px",
            left: "8px",
        },
    },
    hide: {
        visibility: "hidden",
    },
    filterStatusTxt: {
        fontWeight: "300",
    },
}));

const SearchParameters = ({ handleNearBy, isWide }) => {
    const { t } = useTranslation('common');
    const classes = useStyles({ isWide });
    const [showCategories, setShowCategories] = useState(false);
    const [showCounties, setShowCounties] = useState(false);
    const { state } = useContext(Store);

    const showCategoriesHandler = () => {
        setShowCategories(!showCategories)
        if (showCounties) {
            setShowCounties(false);
        }
    }
    const showCountiesHandler = () => {
        setShowCounties(!showCounties)
        if (showCategories) {
            setShowCategories(false);
        }
    }
    const handleClickAway = () => {
        setShowCategories(false);
        setShowCounties(false);
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={classes.searchParametersContainer}>
                {state.settings.filtersCategories.length > 0 || state.settings.filtersCounties.length > 0 ?
                    <FilterHint />
                    : null}
                <div className={classes.buttonContainer}>
                    <div className={classes.filterMainContainer}>
                        <div className={classes.filterItemContainer}>
                            <Button
                                className={clsx(classes.filterButton/*, showCategories && classes.selectedFilter*/)}
                                onClick={showCategoriesHandler}
                                startIcon={(showCategories) ? <KeyboardArrowUpIcon className={classes.arrowIcon} /> : <KeyboardArrowDownIcon className={classes.arrowIcon} />}
                            >
                                {t("general.categories")}&nbsp;
                    </Button>

                            <Collapse in={showCategories}>
                                <div className={classes.filterListContainer}>
                                    <Collapse in={showCategories}>
                                        <ListCategories
                                            setShowCategories={setShowCategories}                                            
                                            timeOut={500}
                                        />
                                    </Collapse>
                                </div>
                            </Collapse>

                        </div>
                        <div className={classes.filterItemContainer}>
                            <Button
                                className={clsx(classes.filterButton/*, showCounties && classes.selectedFilter*/)}
                                onClick={showCountiesHandler}
                                startIcon={(showCounties) ? <KeyboardArrowUpIcon className={classes.arrowIcon} /> : <KeyboardArrowDownIcon className={classes.arrowIcon} />}
                            >
                                {t("general.counties")}
                            </Button>
                            <Collapse in={showCounties}>
                                <div className={classes.filterListContainer}>
                                    <Collapse in={showCounties}>
                                        <ListCounties
                                            setShowCategories={setShowCounties}
                                            timeOut={500}
                                        />
                                    </Collapse>
                                </div>
                            </Collapse>

                        </div>

                    </div>


                    <Button
                        color="default"
                        className={classes.buttonLocation}
                        startIcon={<MyLocationIcon className={classes.myLocationIcon} />}
                        onClick={handleNearBy}
                        data-testid={"searchNearby-button"}
                    >
                        {t('general.museumsNearby')}
                    </Button>


                </div>

            </div>
        </ClickAwayListener>
    );
}

export default SearchParameters;
