import {useEffect, useState} from "react";


const geoError = (err) => {
  console.warn("Geolocation error. Err: ", err);
};

const geoOptions = {
  enableHighAccuracy: true,
  timeout: 9999,
  maximumAge: 4 * 60 * 1000
};

const usePosition = () => {
  const [position, setPosition] = useState({lat: null, lng: null});
  //console.log(position);
  useEffect( () => {
    if (position.lat && position.lng){
      return;
    }
    const geoUpdate = (pos) => {
      try {
        const { latitude: lat, longitude: lng } = pos.coords;
        if (!(lat && lng)) {
          throw Error("Can't retrieve lat or lng");
        }
        setPosition((prev) => {
          return {...prev, lat: lat, lng: lng};
        });
      } catch (err) {
        console.warn("Can't get user position. Error: ", err);
      }
    };
    navigator.geolocation.getCurrentPosition(geoUpdate, geoError, geoOptions);
  }, [])
  return position;
}

export default usePosition;
