import React from "react";
import styled from "styled-components";
import ListCategories from "../ListCategories/listCategories";
import ListCounties from "../ListCounties/listCounties";

const FilterContainer = styled.div`
    box-sizing: border-box;
    margin-top: 10px;    
    display: ${props => props.isVisible ? "flex" : "none"};
    align-self: ${props => props.sb_variant === "small" ? "flex-start" : "flex-end"};
    color: #000;
    background-color: #FFF;
    z-index: 2;
    width: fit-content;
    border-radius: 16px;
    padding: 16px 5px 5px 16px;
    overflow: hidden;    
`;

const FilterInnerContainer = styled.div`
    overflow-y: auto;        
    overflow-x: hidden;
    
    scrollbar-width: thin; //firefox
    scrollbar-color: var(--gray500); //firefox 
    &::-webkit-scrollbar {         
        width: 8px;        
    }
    &::-webkit-scrollbar-track {        
    }
    &::-webkit-scrollbar-thumb{
        background: lightgray;
        opacity: 0.5;
        border-radius: 20px;

        &:hover{
            opacity: 1;
            background: var(--gray500);
        }        
    }    
`;


const SearchBarFilters = ({selectorState, sb_variant, isCategory=false, isCounty=false}) => {

    return (
        <>
            {!isCounty && 
            <FilterContainer 
            sb_variant={sb_variant} 
            isVisible={selectorState[0]} >
                <FilterInnerContainer>
                    <ListCategories />
                </FilterInnerContainer>
            </FilterContainer>}

            {!isCategory &&
            <FilterContainer 
            sb_variant={sb_variant} 
            isVisible={selectorState[1]} >
                <FilterInnerContainer>
                    <ListCounties />
                </FilterInnerContainer>
            </FilterContainer>}

        </>
    )
}

export default SearchBarFilters;