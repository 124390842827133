

const getMuseumAltId = (museum, museumIdType) =>{
    if (!museum){
        return undefined;
    }

    const alternativeIds = museum.identifiers;
        if (!alternativeIds){
            return undefined;
        }

    const alternativeIdEntry = alternativeIds.find(altId => {  
          
        return altId.system ===  museumIdType
    });

    if (!alternativeIdEntry){        
        return undefined;
    }

    return alternativeIdEntry.id;
}



export const getDigitaltMuseumId  = (museum) => {
    return getMuseumAltId(museum, "DigitaltMuseum");
}