import React, {useContext, useMemo, useRef} from 'react';
import styled from "styled-components";
import FilterHintItem from "../FilterHintItem/filterhintitem";
import {Store} from "../../Store";
import {useTranslation} from "react-i18next";
import clsx from "clsx";

const Container = styled.div`
  margin: ${props => props.compact ? "5px 5px 5px 5px" : "15px 5px 5px 5px"};
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 1.2px;
  color: ${props => props.color === 'white' ? '#FFF'  : 'rgba(0,0,0,0.87)'};
`;

const FilterHint = ({
              color = 'white',
              compact = false
                    }) => {
  const { t } = useTranslation('common');
  const tRef = useRef(t);
  const { state, dispatch } = useContext(Store);
  const items = useMemo(() => {
    let _items = [];
    let filters = {categories: state.settings.filtersCategories, counties: state.settings.filtersCounties};
    filters.categories.length > 0 && _items.push({type: tRef.current('plurals.category', {count: filters.categories.length}).toLowerCase(), amount: filters.categories.length});
    filters.counties.length > 0 && _items.push({type: tRef.current('plurals.county', {count: filters.counties.length} ).toLowerCase(), amount: filters.counties.length});
    return _items;
  }, [state.settings]);

  const removeFilterHandler = (type) => {
    const categoryFiltersLen = state.settings.filtersCategories.length;
    const countyFiltersLen = state.settings.filtersCounties.length;
    let payloadType =  '';
    if (type.toLowerCase() === t('plurals.category', {count: categoryFiltersLen}).toLowerCase()){
      payloadType = 'SET_SETTINGS_FILTERSCATEGORIES';
    } else if (type.toLowerCase() === t('plurals.county', {count: countyFiltersLen}).toLowerCase()) {
      payloadType = 'SET_SETTINGS_FILTERSCOUNTIES';
    }
      dispatch({
        type: payloadType,
        payload: []
      });
  };

  return (
    <Container compact={compact}>
      {!compact && <Title color={color}>
        Filters:
      </Title>}
      { items.map( item => {
          return(<FilterHintItem color={color} key={item.type} type={item.type} amount={item.amount} compact={compact} onClick={removeFilterHandler}/>)
      })}
    </Container>
  );
};

export default FilterHint;
