import * as R from 'ramda';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener } from '@material-ui/core';

const useStyles = makeStyles({
  sendContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: 10,
    margin: 10,
    borderRadius: 5,
    boxShadow: '1px 1px 7px -1px rgba(0,0,0,0.25)',
    justifyContent: 'flex-start',
    minHeight: 'min-content',
  },
  textField: {
    paddingBottom: 7,
  },
});

const StyledTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.54)',
        borderWidth: '1px',
      },
    },
  },
})(TextField);

export default ({ title, setSendEmailVisible }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const [messageError, setMessageError] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    const isMessageValid = R.length(message) > 6;
    if (isMessageValid)
      //window.location.href =
      window.open(
        'mailto:' +
        'post@kulturit.org' +
        `?subject=${t('emailForm.subject')}${": "}${title}&body=${message}`, "_blank");
    else if (!isMessageValid) setMessageError(true);
  };

  return (
    <ClickAwayListener onClickAway={() => { setSendEmailVisible(false) }}>
      <div className={classes.sendContainer}>
        <form onSubmit={handleSubmit}>
          <StyledTextField
            className={classes.textField}
            label={t('emailForm.message')}
            variant="outlined"
            multiline
            required
            rows={4}
            error={messageError}
            fullWidth
            helperText={messageError ? t('emailForm.incorrectEntry') : ''}
            value={message}
            onChange={e => {
              setMessage(e.target.value);
              setMessageError(false);
            }}
          />
          <Button type="submit" variant="contained">
            {t('emailForm.send')}
          </Button>
        </form>
      </div>
    </ClickAwayListener>
  );
};
