import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import { CardContent, Typography, CardMedia, Button, IconButton } from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import * as R from "ramda";
import { useTranslation } from 'react-i18next';
import { Store } from '../../Store';
import { Close as IconClose, MyLocation as MyLocationIcon } from '@material-ui/icons';
import useFallbackTrans from "../../hooks/useFallbackTrans";

/*

    TODO:

    - Closeable
    - Make non-clicktru background.



*/

//const smallWidth = 650;

const useStyles = makeStyles({
    componentContainer: {
        display: "flex",
        flexDirection: "column",
        position: props => props.isWide ? "absolute" : "relative",
        zIndex: 9999,
        //backgroundColor: "rgba(0,0,0,.5)",
        minHeight: props => props.isWide ? "unset" : "40%",
        top: props => (props.isWide && `${props.screenY}px`) || "unset",
        right: props => (props.isWide && `${props.screenX}px`) || "unset",
        //bottom: props => (props.isWide && "unset") || "0px",
        left: props => (props.isWide && "unset") || "0px",
        width: props => (props.isWide && "min(50%, 500px)") || "100%",
        //maxHeight: props => props.isWide ? "unset" : "50vh",
        
    },

    root: {
        position: "relative",
        flex: "1 1",
        padding: props => (props.isWide && "16px 14px") || "0px 14px 16px",
        borderRadius: props => (props.isWide && "10px") || "10px 10px 0px 0px",
        overflowY: "auto",
    },
    media: {
        height: "300px",
        marginTop: "40px",
        objectFit: "cover",
    },
    button: {
        backgroundColor: "#C0392B",
        color: "#FFF",
        textTransform: "none",
        padding: "0.5em 1em",
        fontSize: "12px",
        minWidth: "54px",
        minHeight: "24px",
        alignSelf: "flex-start",
    },
    row: {
        display: "flex",
        justifyContent: "space-between",
    },
    col: {
        display: "flex",
        flexDirection: "column",
    },

    title: {
        fontSize: props => (props.isWide && "1rem") || "18px",
        lineHeight: props => (props.isWide && "21px") || "24px",
        fontWeight: 700,
    },
    info: {
        color: "#999",
        fontSize: "14px",
        marginTop: "7px",
        whiteSpace: "pre-line",
    },
    description: {
        marginTop: 15,
        fontSize: props => (props.isWide && "14px") || "12px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": "5",
        "-webkit-box-orient": "vertical",
    },
    slider: {
        backgroundColor: "#EDEDED",
        margin: "0px auto 20px",
        width: "60px",
        height: "5px",
        //border: "2.5px solid #EDEDED",
        borderRadius: "5px",

    },
    closeButton: {
        position: "absolute",
        top: "-8px",
        right: "0px",
        zIndex: 10000,
        color: "#EDEDED",
    },

    distanceRow: {
        display: "flex",
        alignItems: "center",
    },

    distanceIcon: {
        height: "11px",
        width: "11px",
        transform: "translateY(2px)",
        marginRight: "5px",
      },
});

const MuseumInfoCard = ({ activeMarker, setActiveMarker, screenPosition, isWide }) => {
    const { t } = useTranslation('common');
    const history = useHistory();
    const { state } = useContext(Store);


    const museumName = useFallbackTrans(activeMarker.name);
    const museumDescription = useFallbackTrans(activeMarker.description);
    const museumVisitAddress = useFallbackTrans(activeMarker.visitAddress);
    const distanceFormat = (distance) => {
        if (distance >= 1000) {
            return (distance / 1000).toFixed(2).toString().replace('.', ',') + " km";
        }
        return distance.toString().replace('.', ',') + " m";
    }
    const museumDistance = activeMarker.distance ? distanceFormat(activeMarker.distance) : "";

    const classes = useStyles({
        screenX: screenPosition.x,
        screenY: screenPosition.y,
        isWide
    });
    // navigate to details screen
    const navigateTo = () => {
        const id = R.prop('uuid', activeMarker);
        history.push({
            pathname: `/museums/${id}`,
            state: { activeMarker },
        });
    };

    return (
        /*<Modal
        open
        >*/
        <div className={classes.componentContainer}>
            {/*<div className={classes.cardContainer}>*/}
            <Card className={classes.root}>
                <IconButton className={classes.closeButton}
                    onClick={() => setActiveMarker({})}>
                    <IconClose /></IconButton>
                <CardContent>
                    {!isWide && <div className={classes.slider}></div>}
                    <div className={classes.row}>
                        <div className={classes.col}>
                            <Typography
                                variant="h2"
                                className={classes.title}
                            >{museumName}</Typography>

                            <Typography
                                className={classes.info}
                            >{museumVisitAddress}</Typography>
                            
                            {museumDistance &&
                                <div className={classes.distanceRow}>
                                    <MyLocationIcon className={classes.distanceIcon} /><Typography
                                        className={classes.info}
                                    >{museumDistance}</Typography>
                                </div>}
                        </div>
                        <Button
                            variant="contained"
                            size="small"
                            className={classes.button}
                            endIcon={<ArrowForwardIcon />}
                            onClick={navigateTo}
                        >{t("general.show")}</Button>
                    </div>
                    <Typography className={classes.description}>{museumDescription}</Typography>
                    {activeMarker.image && <CardMedia
                        className={classes.media}
                        image={activeMarker.image}
                        title={museumName}
                        alt={museumName}></CardMedia>}
                </CardContent>
            </Card>
        </div>

        /* </Modal>*/
    );
};

export default MuseumInfoCard;
