import { makeStyles } from '@material-ui/core/styles';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Header from '../components/Header/header';
import Drawer from '../components/Drawer/drawer';
import Footer from '../components/Footer/footer';
import Main from '../components/Main/main';
import { Store } from "../Store";


const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    display: 'flex', // changed from flex
    flex: "1",
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: "100vh",
    //overflowY: "scroll",  
  },
}));

const HomePage = () => {
  const { state, dispatch } = React.useContext(Store);
  const { t } = useTranslation('common');
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const museums = useMemo(() => state.data.museumsList, [state.data.museumsList]);
  const drawerHandler = React.useCallback(() => {
    setOpen((prev) => !prev);
  }, [])


  const setCurrentPage = () => {
    
    if (state.user.currentPage === "/") {
      return;
    }
    //console.log("state.user.currentPage: ", state.user.currentPage)
    dispatch({
      type: "SET_USER_CURRENTPAGE",
      payload:  "/"
    })
  }
  setCurrentPage();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Museumsguiden.no</title>
      </Helmet>
      {/*<Header
        handleDrawerOpen={drawerHandler}
        openDrawer={open}
        title={t('general.appTitle')}
      />
      {open && (
        <Drawer
          handleDrawerClose={drawerHandler}
          openDrawer={open}
        />
      )}*/}
      <Main museumsList={museums} />
    </div>
  );
};

export default React.memo(HomePage);
