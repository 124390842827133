import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import clsx from "clsx";

const useStyles = makeStyles({
    infoLineText: {
        fontWeight: 400,
        fontSize: props => (props.isWide && "1rem") || "12px",
        lineHeight: "21px",

        "&:not(:last-of-type)": {
            marginBottom: "1em",
        }
    },

    multiLined: {
        whiteSpace: "pre-line",
        lineHeight: "25px",
    },

    overflowHandler: {
        whiteSpace: "unset",
      },
});
const InfoLineText = ({
    children,
    isWide,
    isMultilined=false,
    hasOverflow=false
    
}) => {
    const classes = useStyles({ isWide: isWide });
    return (
        <Typography className={clsx(classes.infoLineText, isMultilined && classes.multiLined, hasOverflow && classes.overflowHandler)} variant="h4">
            {children}
        </Typography>
    );
}
export default InfoLineText;