import {
	Button,
	ClickAwayListener,
	IconButton,
	InputBase,
	TextField,
} from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { ReactComponent as SearchIconBase } from "../../assets/images/magnifier_search.svg";
import ListCategories from "../ListCategories/listCategories";
import SearchBarSelectorBtn from "./searchBarSelectorBtn";
import ListCounties from "../ListCounties/listCounties";
import { useTranslation } from "react-i18next";
import { Store } from "../../Store";
import { useHistory } from "react-router-dom";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SearchBarFilters from "./searchBarFilters";
import ButtonDecoration from "./buttonDecoration";
import GpsFixedRoundedIcon from "@material-ui/icons/GpsFixedRounded";
import ExploreMuseumsNearbyBubble from "./exploreMuseumsNearbyBubble";
import { text_sm, text_xs } from "../../style/styles";
import {
	CheckBoxOutlineBlankOutlined,
	CheckBoxOutlined,
} from "@material-ui/icons";

const Container = styled.div`
	display: flex;
	justify-content: center;
	height: fit-content;
	width: 100%;
	box-sizing: border-box;
	max-height: 400px;
	max-width: ${props => (props.sb_variant === "medium" ? "446px" : "unset")};
	opacity: ${props => (props.semiTransparent ? 0.8 : 1.0)};
	transition: opacity 0.5s ease-in-out;

	&:focus-within,
	&:hover {
		opacity: 1;
	}
`;





const SubmitButton = styled(Button)`
	flex-shrink: 0;
	display: ${props => (props.isVisible ? "flex" : "none")};
	color: #fff;
	align-items: center;
	justify-content: center;
	border-radius: 90px;
	background-color: var(--blaze500);
	width: 152px;
	height: 82px;
	margin-left: 8px;
	font-size: 20px;
	font-weight: 700;
	line-height: 26px;
	letter-spacing: -0.005em;
	text-transform: none;
	box-shadow: 0px 18px 58px rgba(0, 0, 0, 0.25);

	&:hover {
		background-color: #b06010;
	}
`;

const SubmitButtonText = styled.p``;

const SearchAndFiltersContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const SearchIconButton = styled(IconButton)`
	z-index: 10;
	color: var(--gray600);
	display: flex;
	align-items: center;
	padding-left: ${props => (props.sb_variant === "medium" ? "8px" : "0")};
	padding-right: ${props => (props.sb_variant === "medium" ? "8px" : "18px")};
	padding-bottom: ${props => (props.sb_variant === "medium" ? "8px" : "18px")};
	padding-top: ${props => (props.sb_variant === "medium" ? "8px" : "12px")};
`;

const InputAndIconContainer = styled.div`
	display: flex;
	flex-grow: 1;
`;

const SearchHitsContainer = styled.div`
	color: #000;
	margin-top: 18px;
	right: 35px;
	position: absolute;
	opacity: ${props => props.hitsVisible ? 1.0: 0.0};
	${text_xs}	
	transition: opacity ease-in-out 0.5s;
`;

const SearchInputInnerContainer = styled.div`
	display: flex;
	flex-direction: ${props => (props.sb_variant === "medium" ? "column" : "row")};
	width: 100%;
	position: relative;
	/*
	&:focus-within ${SearchHitsContainer}{
		opacity: 0.0;
	}

	&:hover ${SearchHitsContainer}{
		opacity: 0.0;
	}
	*/
`;

const BackIconButton = styled(IconButton)`
	display: none;
	z-index: 10;
	align-items: center;

	${props =>
		props.searchBarOverlayOpen &&
		`
        display: flex;
    `}
`;

const OverlaySelectorButtonContainer = styled.div`
	display: none;

	${props =>
		props.searchBarOverlayOpen &&
		`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    `}
`;

const NearbyButton = styled(Button)`
	text-transform: none;
	align-self: flex-start;
	padding: 6px 16px;
`;



const SearchInput = styled(InputBase)`
	font-family: var(--akkurat);
	font-size: ${props =>
		props.sb_variant === "big"
			? "18px"
			: props.sb_variant === "small"
			? "16px"
			: "12px"};
	font-weight: 400;
	line-height: ${props => (props.sb_variant === "big" ? "28px" : "20px")};
	width: ${props => (props.sb_variant !== "small" ? "48%" : "100%")};
	padding-left: ${props =>
		props.sb_variant === "big"
			? "32px"
			: props.sb_variant === "medium"
			? "14px"
			: "32px"};
	flex-grow: 1;
	&:focus-within {
		/* PREVENT ZOOM ON IOS */
		font-size: ${props =>
			props.sb_variant === "big"
				? "18px"
				: props.sb_variant === "small"
				? "16px"
				: "16px"};
	}

	

	@media only screen and (min-width: 1024) {
		font-size: ${props => (props.sb_variant === "big" ? "18px" : "14px")};
	}
`;




const BubbleContainer = styled.div`
	display: block;
	background: yellow;
`;

const HorizontalDivider = styled.div`
	width: 100%;
	height: 0px;
	border-top: 2px solid #000;
	opacity: 0.1;
`;

const VerticalDivider = styled.div`
	//position: absolute;
	//top: 0;
	//bottom: 0;
	width: 2px;
	background-color: #000;
	opacity: 0.1;
	left: ${props => props.left};
	display: ${props => (props.hideOnSmall ? "none" : "block")};
	@media only screen and (min-width: 800px) {
		display: block;
	}
`;

const SearchIcon = styled(SearchIconBase)`
	display: flex;
	margin-left: ${props => props.leftMargin || "5px"};
	margin-right: ${props => props.rightMargin || "5px"};
	height: ${props => props.size || "18px"};
	width: ${props => props.size || "18px"};
	path {
		stroke: ${props => (props.is_white ? "#FFF" : "#5A5A62")};
	}
`;

const SearchBarSelectionBtnRow = styled.div`
	display: flex;
	flex-direction: row;
	//position: absolute;
	//bottom: 8px;
	margin-left: 12px;
`;

const SearchMoveButton = styled(Button)`
	${text_xs}
	text-transform: none;
	display: flex;
	color: ${props => props.searchOnMove ? "var(--blaze600)" : "none"};
	
	@media only screen and (min-width: 800px) {
		display: flex;
	}
`;

const SearchNearbyButton = styled(Button)`
	${text_xs}
	text-transform: none;
	display: flex;
	
`;

const SearchInputContainer = styled.div`
	position: relative;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	position: relative;
	background-color: #fff;

	min-width: ${props =>
		props.sb_variant === "big"
			? "650px"
			: props.sb_variant === "medium"
			? "300px"
			: "80%"};

	width: 100%;
	height: ${props =>
		props.sb_variant === "big"
			? "82px"
			: props.sb_variant === "medium"
			? "96px"
			: "76px"};
	border-radius: ${props =>
		props.sb_variant === "big"
			? "24px"
			: props.sb_variant === "medium"
			? "12px"
			: "24px"};
	flex-shrink: 0;
	box-shadow: ${props =>
		props.hasShadow ? "0px 18px 58px rgba(0, 0, 0, 0.25)" : "none"};
	transition: position ease-in-out 0.5s;

	/*&:focus-within ${BubbleContainer} {
		display: block;
	}*/

	${props =>
		props.sb_variant === "small" &&
		props.searchBarOverlayOpen &&
		`
        
            position: fixed;
            top: 0;            
            left: 0;           
            width: 100vw;            
            height: 100vh;
            z-index: 100;
            border-radius: 24px 24px 0 0;
            flex-direction: column;
            padding: 22px 24px;
            justify-content: flex-start;
            overflowy-y: auto;
			overflow-x: hidden;
    ` /*:
     `
        
            position: relative;
            top: unset;            
            left: unset;           
            width: 100%;            
            height: unset;
            z-index: unset;
            border-radius: 24px;
            flex-direction: row;
            padding: unset;
            justify-content: space-between;
            overflow: auto;
`*/}
`;

/**
 *
 * @param {"big" | "medium" | "small" } sb_variant
 */
const SearchBar = ({
	searchSubmitHandler = null,
	sb_variant = "big",
	onMapPage = false,
	hasShadow = true,
	searchOnMove = null,
	setSearchOnMove = null,
	semiTransparent = false,
	showHits=false,
	data=null,
	searchTermProp = null,	
}) => {
	const { t } = useTranslation("common");
	// [category, county]
	const searchInputRef = useRef(null);
	const [selectorState, setSelectorState] = useState([0, 0]);
	const [searchTerm, setSearchTerm] = useState("");
	const [searchBarOverlayOpen, setSearchbarOverlayOpen] = useState(false);
	const history = useHistory();
	const { state, dispatch } = useContext(Store);
	const [bigBubbleVisible, setBigBubbleVisible] = useState(false);
	const [hitsVisible, setHitsVisible] = useState(true);
	//console.log("selectorState: ", selectorState);
	//console.log("searchBarOverlayOpen: ", searchBarOverlayOpen);

	const handleNearBy = async () => {		
		dispatch({
			type: "SET_SETTINGS_SEARCHNEARBY",
			payload: true
		});
		history.push({
			pathname: "/museums",
			//search: `lat=${state.user.lat}&lng=${state.user.lng}`,
			//position: { lat: state.user.lat, lng: state.user.lng },
		});
	};

	const handleSearch = () => {
		if (onMapPage && searchSubmitHandler) {
			searchSubmitHandler(searchTerm);
		} else {
			history.push(`/museums?query=${searchTerm}`);
		}
	};

	// on enter pressed
	const handleKeyPress = e => {
		if (e.key === "Enter") {
			handleSearch();
		}
	};

	useEffect(()=>{
		if(searchTermProp === null || searchTermProp === searchTerm ) return;
		setSearchTerm(searchTermProp);
	}, [searchTermProp])
	

	return (
		<ClickAwayListener
			onClickAway={() => {
				searchInputRef.current.children[0].blur();
				setSelectorState([0, 0]);
				setBigBubbleVisible(false);
			}}
		>
			<Container
				onClick={() => {
					!searchBarOverlayOpen && setSearchbarOverlayOpen(true);
				}}
				sb_variant={sb_variant}
				semiTransparent={semiTransparent}
			>
				<SearchAndFiltersContainer>
					<SearchInputContainer
						sb_variant={sb_variant}
						searchBarOverlayOpen={searchBarOverlayOpen}
						hasShadow={hasShadow}
					>
						<SearchInputInnerContainer sb_variant={sb_variant}>
							<InputAndIconContainer>
								
								{showHits && <SearchHitsContainer hitsVisible={hitsVisible}> {`(${data.length} ${t("searchBar.hits")})`}</SearchHitsContainer>}
								
								{sb_variant === "small" && (
									<BackIconButton
										searchBarOverlayOpen={searchBarOverlayOpen}
										onClick={() => {
											setSearchbarOverlayOpen(false);
										}}
									>
										<ArrowBackIcon />
									</BackIconButton>
								)}
								
								<SearchInput
									ref={searchInputRef}
									type="search"
									sb_variant={sb_variant}
									placeholder={
										sb_variant === "big"
											? t("searchBar.searchMuseums")
											: t("searchBar.searchMuseumsShort")
									}
									value={searchTerm}
									onChange={e => setSearchTerm(e.target.value)}
									onKeyPress={handleKeyPress}
									onFocus={()=>{
										setHitsVisible(false);
										setBigBubbleVisible(true);}}
									onBlur={()=>{setHitsVisible(true)}}
								/>
								{sb_variant === "medium" && (
									<SearchIconButton sb_variant={sb_variant} onClick={handleSearch}>
										<SearchIcon size={"12px"} />
									</SearchIconButton>
								)}
							</InputAndIconContainer>
							{sb_variant !== "small" && sb_variant !== "medium" && (
								<>
									<VerticalDivider />
									<SearchBarSelectorBtn
										sb_variant={sb_variant}
										selectorState={selectorState}
										setSelectorState={setSelectorState}
										isCategory
									/>
									<VerticalDivider />
									<SearchBarSelectorBtn
										sb_variant={sb_variant}
										selectorState={selectorState}
										setSelectorState={setSelectorState}
									/>
								</>
							)}
							{sb_variant === "small" && (
								<SearchIconButton sb_variant={sb_variant} onClick={handleSearch}>
									<SearchIcon />
								</SearchIconButton>
							)}

							{sb_variant === "medium" && (
								<>
									<HorizontalDivider />
									<SearchBarSelectionBtnRow>
										<SearchBarSelectorBtn
											sb_variant={sb_variant}
											selectorState={selectorState}
											setSelectorState={setSelectorState}
											isCategory
										/>
										<VerticalDivider />
										<SearchBarSelectorBtn
											sb_variant={sb_variant}
											selectorState={selectorState}
											setSelectorState={setSelectorState}
										/>
										<VerticalDivider hideOnSmall={true} />
										{/*state.settings.searchNearby ? (
											<SearchNearbyButton
												
												onClick={() => {
													dispatch({
														type: "SET_SETTINGS_SEARCHNEARBY",
														payload: false
													});
													//console.log(state.settings.searchNearby)
												}}
												startIcon={													
														<CheckBoxOutlined />													
												}
											>
												{t("searchBar.nearby")}
											</SearchNearbyButton>
											) : (*/}
											<SearchMoveButton
												searchOnMove={searchOnMove}
												onClick={() => {
													setSearchOnMove(!searchOnMove);
												}}
												startIcon={
													!searchOnMove ? (
														<CheckBoxOutlineBlankOutlined />
													) : (
														<CheckBoxOutlined />
													)
												}
											>
												{t("searchBar.searchWithMap")}
											</SearchMoveButton>
										{/*)*/}
									</SearchBarSelectionBtnRow>
								</>
							)}
						</SearchInputInnerContainer>

						{
							state.user.lat && state.user.lng && sb_variant === "big" && !searchTerm && bigBubbleVisible && (
								
								<BubbleContainer>
									<ExploreMuseumsNearbyBubble />
								</BubbleContainer>
								
								
							)
						}
						{sb_variant === "small" && (
							<OverlaySelectorButtonContainer
								searchBarOverlayOpen={searchBarOverlayOpen}
							>
								{state.user.lat && state.user.lng && !searchTerm && (
									<NearbyButton onClick={handleNearBy}>
										<ButtonDecoration>
											<GpsFixedRoundedIcon />
										</ButtonDecoration>{" "}
										{t("searchBar.searchNearby")}
									</NearbyButton>
								)}

								<SearchBarSelectorBtn
									sb_variant={sb_variant}
									selectorState={selectorState}
									setSelectorState={setSelectorState}
									isCategory
								/>

								<SearchBarFilters
									sb_variant={sb_variant}
									selectorState={selectorState}
									isCategory
								/>

								<SearchBarSelectorBtn
									sb_variant={sb_variant}
									selectorState={selectorState}
									setSelectorState={setSelectorState}
								/>

								<SearchBarFilters
									sb_variant={sb_variant}
									selectorState={selectorState}
									isCounty
								/>
							</OverlaySelectorButtonContainer>
						)}
					</SearchInputContainer>

					{sb_variant !== "small" && (
						<SearchBarFilters selectorState={selectorState} />
					)}
				</SearchAndFiltersContainer>

				<SubmitButton isVisible={sb_variant === "big"} onClick={handleSearch}>
					<SearchIcon is_white={true} />
					<SubmitButtonText>{t("searchBar.search")}</SubmitButtonText>
				</SubmitButton>
			</Container>
		</ClickAwayListener>
	);
};

export default SearchBar;
