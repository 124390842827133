import { Link, makeStyles } from "@material-ui/core";
import React from "react";
import clsx from "clsx";

const useStyles = makeStyles({
    infoLineLink: {
        display: "flex",
        fontWeight: 500,
        fontSize: props => (props.isWide && "1rem") || "12px",
        lineHeight: "21px",
        overflow: "hidden",
        color: "#C0392B",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        cursor: "pointer",

        "& + $infoLineLink": {
            marginTop: "1em",
        }
    },

    overflowHandler: {
        whiteSpace: "unset",
    },
});
const InfoLineLink = ({
    children,
    href,
    isWide,
    hasOverflow = false,
    onClick
    
}) => {

    const classes = useStyles({isWide: isWide});
    return (
        <Link
            className={clsx(classes.infoLineLink, hasOverflow && classes.overflowHandler)}
            variant="body2"
            href={href}
            target="_blank"  
            onClick={onClick}          
        >
            {children}
        </Link>
    );
}

export default InfoLineLink;