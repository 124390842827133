import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  PinterestShareButton,
  PinterestIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';

const useStyles = makeStyles({
  shareContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 10,
    minHeight: 'min-content',
  },
});

export default ({ url, media, isWide}) => {
  const classes = useStyles();
  const iconSize = isWide ? 50 : 30;
  const iconMarginLeft = isWide ? 20 : 10;

  return (
    <div
      className={classes.shareContainer}      
    >
      <FacebookShareButton url={url} >
        <FacebookIcon size={iconSize} round={true} />
      </FacebookShareButton>
      <PinterestShareButton url={url} style={{marginLeft: iconMarginLeft}} media={media}>
        <PinterestIcon size={iconSize} round={true} />
      </PinterestShareButton>
      <TwitterShareButton url={url} style={{marginLeft: iconMarginLeft}}>
        <TwitterIcon size={iconSize} round={true} />
      </TwitterShareButton>
    </div>
  );
};
