
/**
 * Filter museum duplicates based on lat / lng coordinates.
 */
const handleDuplicate = museums => {  
  const duplicates = {};

  // Remove museums without position
  const filteredMuseums = museums.filter((m, index1) => {
    const lat1 = m.position.lat;
    const lng1 = m.position.lng;

    // Skip museums without lat / lng
    if (!lat1 || !lng1) {
      console.log("Position missing:", m);
      return false;
    }
    return true;
  });

  const adjustedMuseums = filteredMuseums.map((m, index1) => {
    const lat1 = m.position.lat;
    const lng1 = m.position.lng;

    

    const findDuplicate = (el, index2) => {
      const lat2 = el.position.lat;
      const lng2 = el.position.lng;

      const isDuplicate =
        lat1 === lat2 &&
        lng1 === lng2 &&
        index1 !== index2 &&
        !duplicates[index2];

      if (isDuplicate) {
        duplicates[index1] = index1;
      }
      console.log()
      return isDuplicate;
    };
    const foundDuplicate = museums.find(findDuplicate);
    if(Boolean(foundDuplicate)){
      //console.log(m.uuid, " og ", foundDuplicate.uuid);
      m.position.lat += 0.00005;
    }
    return m;
  });

  return adjustedMuseums;
};

export default handleDuplicate;
