import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { Drawer as MUIDrawer } from '@material-ui/core';
import { ChevronRight} from "@material-ui/icons";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import clsx from "clsx";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  listItemText: {
    textAlign: 'right'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

const Drawer = ({ openDrawer, handleDrawerClose }) => {
  const history = useHistory();
  const { t } = useTranslation('common');
  const classes = useStyles();

  /*
  const navigateTo = text => {
    if (text === t('general.home')) history.push('/');
    if (text === t('general.museums'))
      history.push({
        pathname: '/museums',
      });
  };

  */

  return (
    <MUIDrawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={openDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronRight/>
        </IconButton>
      </div>
      <List>
        {[[t('general.home'), "/"], [t('general.museums'), "/museums"], [t("general.categories"), "/categories"], [t("general.counties"), "/counties"]].map((text, index) => (

          <ListItem button key={text[0]} onClick={() => history.push(text[1])}>
            <ListItemText className={classes.listItemText} primary={text[0]} />
          </ListItem>
        ))}


        {/*[t('general.home'), t('general.museums')].map(text => (
          <ListItem button key={text} onClick={() => navigateTo(text)}>
            <ListItemText primary={text} />
          </ListItem>
        ))*/}
      </List>
    </MUIDrawer>
  );
};

export default React.memo(Drawer);
