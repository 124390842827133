import React, { useState, useEffect, useContext, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, FormGroup, Checkbox, FormControlLabel, Button } from "@material-ui/core";
import { Store, } from "../../Store";
import { CheckBoxOutlined, CheckBoxOutlineBlankOutlined } from "@material-ui/icons";
import { sortCategories, updateCheckboxes } from "../../helpers/filterHelpers";
import { useTranslation } from "react-i18next";
import { translate } from "../../hooks/useFallbackTrans";
import styled from "styled-components";
import { text_base } from "../../style/styles";

/* TODO: Merge into single component for both counties and categories */

const useStyles = makeStyles({

    buttonRow: {
        display: "flex",
        marginLeft: "-12px", // compensate for padding       
    },

    toggleFilterButton: {
        marginLeft: "8px",
        
        fontSize: "14px",
        textTransform: "none",
        fontWeight: "400",
        whiteSpace: "nowrap",
    },

    toggleFilterIcon: {
        width: "24px",
        height: "24px",
        marginRight: "-3px",
    },

    categoriesLabelRoot: {
        marginLeft: "0px",
    },

    categoriesLabel: {
        display: "block",
        fontSize: "14px",
        lineHeight: "19px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "70vw",
    },

    categoriesCheckbox: {
        padding: "5px 5px 5px 0px",
    },

    horizontalLine: {
        display: "block",
        width: "100%",
        height: "2px",
        backgroundColor: "#EDEDED",
        margin: "5px 0px",
    },

    applyButton: {
        textTransform: "none",
    },

    helpText: {
        fontFamily: "var(--akkurat)",
        fontSize: "12px",
        fontWeight: "300",
    },


});

const CategoriesLabel = styled(Typography)`
    display: block;
    ${text_base}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 70vw;
    user-select: none;
`;

const ToggleFilterButton = styled(Button)`
        margin-left: 8px;
        ${text_base}        
        text-transform: none;        
        white-space: nowrap;
`;

const otherCategoryValue = "a2ce370b-e955-4a1d-bbfb-3f1a375cdbd2";
let categoryTimer;

const ListCategories = ({ useShortName = false, isWide, timeOut = 2000 }) => {
    const { t } = useTranslation('common');
    const { state, dispatch } = useContext(Store);
    const classes = useStyles({ isWide });
    const [checkerState, setCheckerState] = useState({});
    const [categories, setCategories] = useState([]);
    const categoryNameFieldRef = React.useRef(useShortName ? "alternativeName" : "displayValue");
    const stateRef = React.useRef(state);
    const dispatchRef = React.useRef(dispatch);
    const hasChangedRef = React.useRef({ hasChanged: false });


    const handleChange = (e) => {
        hasChangedRef.current.hasChanged = true;
        setCheckerState({ ...checkerState, [e.target.name]: e.target.checked })
    };

    const changeAllCheckers = (bool) => {
        const tmp_checkerState = { ...checkerState };
        Object.keys(tmp_checkerState).forEach((el) => tmp_checkerState[el] = bool);
        hasChangedRef.current.hasChanged = true;
        setCheckerState(tmp_checkerState);
    }

    const selectAll = () => changeAllCheckers(true);
    const selectNone = () => changeAllCheckers(false);


    const filterMuseumsByCategory = (checkedCategories) => {
        const filteredCategories = checkedCategories.filter((category) => category);

        dispatchRef.current({
            type: 'SET_SETTINGS_FILTERSCATEGORIES',
            payload: filteredCategories
        });
    }


    const applyFilter = () => {

        const checkerState_tmp = checkerState;
        const checkedCategories = Object.entries(checkerState_tmp).map(category => {
            return category[1] ? category[0] : null;
        });
        filterMuseumsByCategory(checkedCategories);

    }


    useEffect(() => {

        const filterMuseumsByCategory = () => {
            const filteredCategories = checkedCategories.filter((category) => category);

            dispatchRef.current({
                type: 'SET_SETTINGS_FILTERSCATEGORIES',
                payload: filteredCategories
            });
        }

        const filterState = state.settings.filtersCategories;
        const hasChanged = hasChangedRef.current.hasChanged;

        if (!hasChanged) {
            return
        }
        hasChangedRef.current.hasChanged = false;

        const checkedCategories = Object.entries(checkerState).map(category => {
            return category[1] ? category[0] : null;
        });
        const filteredCategories = checkedCategories.filter((category) => category);

        clearTimeout(categoryTimer);
        categoryTimer = setTimeout(filterMuseumsByCategory, timeOut);

        return (() => clearTimeout(categoryTimer));

    }
        , [checkerState])


    // update checkboxes on state change
    useEffect(() => {
        if (!setCheckerState) {
            return;
        }
        const categoriesList = state.data.categoriesList;
        const filterState = state.settings.filtersCategories;
        const checkerState_tmp = updateCheckboxes(categoriesList, filterState)
        setCheckerState(checkerState_tmp);

    }, [state.settings.filtersCategories])



    // fetches and sorts categories
    useEffect(() => {

        if (!state.data.categoriesList) {
            return;
        }
        const categoriesList = state.data.categoriesList;
        const categoriesTmp = Object.values(categoriesList);

        sortCategories(categoriesTmp, useShortName);
        setCategories(categoriesTmp);

        const filterState = state.settings.filtersCategories;
        const checkerState_tmp = updateCheckboxes(categoriesList, filterState)
        setCheckerState(checkerState_tmp);

    }, [state.data.categoriesList]);



    return (
        <div className={classes.categoriesContainer}>
            <div className={classes.buttonRow}>
                {Object.values(checkerState).every((el) => el === true) ?

                    <ToggleFilterButton                        
                        onClick={selectNone}
                        startIcon={<CheckBoxOutlined className={classes.toggleFilterIcon} />}>{`${t("filterText.select")} ${t("filterText.none")}`}</ToggleFilterButton>
                    :
                    <ToggleFilterButton                        
                        onClick={selectAll}
                        startIcon={<CheckBoxOutlineBlankOutlined className={classes.toggleFilterIcon} />}>{`${t("filterText.select")} ${t("filterText.all")}`}</ToggleFilterButton>
                }


            </div>
            <div className={classes.horizontalLine}></div>
            <FormGroup>
                {Object.values(categories).map(currentCategory =>
                    <FormControlLabel
                        className={classes.categoriesLabelRoot}
                        key={currentCategory.uuid}
                        data-testid={"checkbox"}
                        control={
                            <Checkbox
                                className={classes.categoriesCheckbox}
                                checked={checkerState[currentCategory.value] === undefined ? false : checkerState[currentCategory.value]}
                                name={currentCategory.value}
                                onChange={handleChange}
                            ></Checkbox>}

                        label={<CategoriesLabel>
                            {translate(currentCategory[categoryNameFieldRef.current], state.settings.locale)}
                        </CategoriesLabel>}
                    ></FormControlLabel>)}
                <div className={classes.horizontalLine}></div>
                {/*<Button
                    className={classes.applyButton}
                onClick={applyFilter}>{t("general.apply")}</Button>*/}
                {/*<Typography className={classes.helpText}>{t("filterText.instructionsCategories")}</Typography>*/}

            </FormGroup>

        </div>
    )
}


export default ListCategories;
