
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useWindowDimensions from '../../hooks/useWindowDimensions';



import styled from 'styled-components';
import NavButtonGroup from '../NavButtonGroup/navButtonGroup';

const drawerWidth = 240;
const smallWidth = 880;

/*
  TODO:

  - Decide drawer design.
  - Add css breakpoint for middle sized screens to prevent oveflow issues.
  

*/

const Container = styled.div`
position: fixed;
bottom: 0;
display: flex;
background: var(--Museumsbakgrunn);
min-height: 85px;
align-items: center;
justify-content: center;    
padding-left: 20px;
padding-right: 20px;
width: 100%;
box-sizing: border-box; 
z-index: 10;  

@media only screen and (min-width: ${smallWidth}px){ 
  display: none;
}
`;


const BottomMenu = () => {
  //const history = useHistory();
  //const { width } = useWindowDimensions();
  //const { t } = useTranslation('common');
  //const classes = useStyles({ isWide: width > smallWidth });








  return (
    <Container>
      <NavButtonGroup />
    </Container>
  );
};

export default BottomMenu;
