import * as R from 'ramda';

export const hasGeo = geoLocation => {
  return R.prop('lat', geoLocation) && R.prop('lng', geoLocation) && true;
};

export const onCurrentPosition = (geoLocation, mapCenter) =>
  R.equals(mapCenter, geoLocation);





export const getDistanceBetweenPoints = (lat, lng, lat2, lng2) => {
  //using a simulator in testing
  var R = 6371e3; // metres
  var φ1 = lat * (Math.PI / 180);
  var φ2 = lat2 * (Math.PI / 180);
  var Δφ = (lat2 - lat) * (Math.PI / 180);
  var Δλ = (lng2 - lng) * (Math.PI / 180);
  var a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) *
    Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return (R * c);

}


