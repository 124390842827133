import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useContext, useState } from 'react';
import { Store } from "../../Store";
import FilterListIcon from '@material-ui/icons/FilterList';
import { Button, FilledInput, InputAdornment} from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import FilterHint from "../FilterHint/filterhint";

const useStyles = makeStyles(theme => ({
  container: {
    boxSizing: "border-box",
    position: "relative",
    display: 'flex',
    flexDirection: "column",
    //justifyContent: 'center',
    //alignItems: 'center',
    //zIndex: 99,
    width: '100%',
    backgroundColor: "#EDEDED",
    padding: /*props => props.isWide ? "10px 60px 10px 60px" :*/ "10px 15px 10px 15px",

  },
  textInput: {
    width: "100%",
    backgroundColor: "#FFF",
    height: "50px",
    fontSize: "16px",
    lineHeight: "25px",    

    "& .MuiFilledInput-input": { 
      padding: 0, // center text
    },


  },

  root: {
    marginLeft: "2px",
  },
  iconButton: {
    padding: 5,
  },
  divider: {
    margin: 3,
  },

  filterButton: {
    textTransform: "none",
    fontSize: "14px",
    fontWeight: "400",
  },

  filterContainer: {
    display: "flex",
    alignItems: "center",
    alignSelf: "flex-start",
  },

  hidden: {
    display: "none",
  }

}));

const SearchInput = ({
  value,
  setShowFilters,
  showFilters,
  onSubmit,
  isWide
}) => {

  const { state } = useContext(Store);
  const classes = useStyles(isWide={isWide});
  const [searchTerm, setSearchTerm] = useState(value);
  const { t } = useTranslation('common');

  const filterTextGen = (filter, listLength) => {
    const filterLength = filter.length;
    return    (listLength === filterLength ||
      filter[0] === "all" || filterLength < 1) ? t("filterText.all") :
      `${filterLength} ${t("filterText.of")} ${listLength}`

  }


  const categoriesLength = Object.keys(state.data.categoriesList).length;  
  const categoriesFilterText = filterTextGen(state.settings.filtersCategories, categoriesLength)   

  const countiesLength = state.data.countiesList.length;  
  const countiesFilterText = filterTextGen(state.settings.filtersCounties, countiesLength);
  const filterText = ((state.settings.filtersCategories[0] ==="all" || state.settings.filtersCategories.length < 1) 
                   && (state.settings.filtersCounties[0] ==="all" || state.settings.filtersCounties.length < 1)) ?
  `${t("filterText.showResultsFrom")} ${categoriesFilterText} ${t("filterText.categories")} ${t("filterText.and")} ${t("filterText.counties")}`
  :
  `${t("filterText.showResultsFrom")} ${categoriesFilterText} ${t("filterText.categories")} ${t("filterText.and")} ${countiesFilterText} ${t("filterText.counties")}`;



  const searchFunc = (e) => {
    e.preventDefault();
    onSubmit && onSubmit(searchTerm);
  };


  return (
    <div className={classes.container}>
      <form 
      className={classes.root} 
      onSubmit={searchFunc}
      data-testid={"form"}>
        <FilledInput
          classes={{ root: classes.textInputRoot }}
          variant="filled"
          size="small"
          className={classes.textInput}
          value={searchTerm || ""}
          onChange={e => setSearchTerm(e.target.value)}
          placeholder={`${t("searchBar.search")}...`}
          inputProps={{
            className: classes.textInputProps
          }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() => setSearchTerm('')}
                className={clsx(!searchTerm && classes.hidden)}
                data-testid={"clear_button"}
                >
                  
                <ClearIcon />

              </IconButton>
            </InputAdornment>
          }
        />
      </form>
      {isWide && !(state.settings.filtersCategories.length === 0 && state.settings.filtersCounties.length === 0) ? <FilterHint color="black"/> : null}
      <div className={classes.filterContainer}>
        <Button
          className={classes.filterButton}
          startIcon={<FilterListIcon />}
          onClick={() => setShowFilters(!showFilters)}
          data-testid={"filterButton"}
          >
          {filterText}
          </Button>
      </div>
    </div>
  );
};

export default SearchInput;
