import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
    infoLineLabel: {
        fontSize: props => (props.isWide && "1rem") || "12px",
        fontWeight: 700,
        lineHeight: "21px",
        minWidth: props => (props.isWide && "143px") || "90px",

    },
});
const InfoLineLabel = ({ children, isWide }) => {
    const classes = useStyles({ isWide: isWide });
    return (
        <Typography className={classes.infoLineLabel} variant="h4">
            {children}
        </Typography>
    );
}
export default InfoLineLabel;