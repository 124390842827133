import { Button, Collapse, Fade, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import React from "react";
import ListCategories from "../ListCategories/listCategories";
import ListCounties from "../ListCounties/listCounties";
import { useTranslation } from 'react-i18next';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from "clsx";

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles({

    filtersContainer: {
        paddingTop: "31px",
        paddingBottom: "31px",
        width: "80%",
        minHeight: "fit-content",
        boxSizing: "border-box",
    },

    filtersHeaderContainer: {
        position: "relative",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",

    },

    filtersHeader: {
        fontSize: "16px",
        textTransform: "uppercase",
        fontWeight: "700",
        lineHeight: "21px",
        marginRight: "auto",
    },

    filterButton: {
        marginLeft: "-8px", // standard padding button
    },

    

    hide: {
        visibility: "hidden",
    },

    closeButtonContainer: {
        position: "absolute",
        right: "30px",
    },


});



const FilterContainer = ({ isWide, setShowCounties, showCounties, setShowCategories, showCategories }) => {
    const { t } = useTranslation('common');
    const classes = useStyles()
    return (
        <>
            <div className={classes.filtersContainer}>

                <div className={classes.filtersHeaderContainer}>
                    <Button
                        className={classes.filterButton}
                        startIcon={showCategories ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        onClick={() => setShowCategories(!showCategories)}
                    >
                        <Typography
                            variant="h3"
                            className={classes.filtersHeader}>{t("general.categories")}
                        </Typography>

                    </Button>

                    <Fade in={showCategories}>
                        <div className={classes.closeButtonContainer}>
                            <IconButton                                
                                onClick={() => setShowCategories(false)}
                            ><ClearIcon />
                            </IconButton>
                        </div>
                    </Fade>

                </div>
                <Collapse in={showCategories}>
                    <div className={clsx(!showCategories && classes.hide)}>
                        <ListCategories
                            setShowCategories={setShowCategories}
                            isWide={isWide}
                        ></ListCategories>
                    </div>
                </Collapse>


                <div className={classes.filtersHeaderContainer}>

                    <Button
                        className={classes.filterButton}
                        startIcon={showCounties ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        onClick={() => setShowCounties(!showCounties)}
                    >
                        <Typography
                            variant="h3"
                            className={classes.filtersHeader}>{t("general.counties")}
                        </Typography>
                    </Button>

                    <Fade in={showCounties}>
                        <div className={classes.closeButtonContainer}>
                            <IconButton                                
                                onClick={() => setShowCounties(false)}
                            ><ClearIcon />
                            </IconButton>
                        </div>
                    </Fade>

                </div>
                <Collapse in={showCounties}>
                    <div className={clsx(!showCounties && classes.hide)}>
                        <ListCounties
                            setShowCounties={setShowCounties}
                            isWide={isWide}
                        ></ListCounties>
                    </div>
                </Collapse>


            </div>            
        </>
    );
}

export default FilterContainer;