import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import {ArrowDropDown, ArrowRight, GpsFixed, AccountTree } from '@material-ui/icons';
import {ReactComponent as PlaceholderIcon} from '../../assets/images/placeholder_icon.svg';
import {Store} from '../../Store';
import {Collapse} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import Button from "../Button/button";
import useFallbackTrans from '../../hooks/useFallbackTrans';

const Container = styled.div`
 display: flex; 
 flex-direction: column;
 background: #FFF;
 cursor: pointer;
 :not(:last-of-type){
 padding-bottom: 10px;
 }
 :first-of-type{
  padding-top: 10px;
 }
`;

const Item = styled.div`
  display: flex;
  padding-bottom: 10px;
  padding-right: 15px;
  :last-of-type{
    border: none;
  }
  :not(:first-of-type){
  border-top: 1px solid #CCC;
  }
  :not(:last-of-type){
  border-bottom: 1px solid #CCC;
  }
  button {
    min-width: fit-content;
    height: fit-content;
    align-self: center;
  }
`;

const Children = styled.div`
  :not(:first-of-type){
  margin-left: 30px;
  }
`;

const ImageContainer = styled.div`
    height: 60px;
    width: 60px;
    min-width: 60px;
    border-radius: 10px;
    overflow: hidden;
  img {
    object-fit: cover;
    height: 100%;
    width: auto;
  }
`;

const ContentContainer = styled.div`
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 6px 15px;
`;
const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    heigh: 25px;
    width: 25px;
  }
  path{
  height: 50px;
  width: 50px;
  }
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #000;
  letter-spacing: 0;
`;


const PlaceholderImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    border-radius: 10px;
    background: #EDEDED;
    overflow: hidden;
    svg {
      height: 80%;
    }
`;

const DescriptionContainer = styled.div`
    display: flex;
    align-items: end;
    font-weight: 400;
    line-height: 19px;
    font-size: 14px;
    letter-spacing: 0;
    color: #999999;
    svg {
      height: 16px;
    }
    > svg:not(:first-child){
      margin-left: 15px;
    }
`;

const Description = styled.div`
   flex: 0 1 auto; 
`;


const Distance = styled.div`
`;

const formatDistance = (dist) => {
  return dist > 1000 ? `${(dist/1000).toFixed(1)} km` : `${dist} m`;
};

const ListItem = ({
                    items = [],
                    uuid,
                    title = '',
                    name = '',
                    description,
                    image = "",
                    distance,
                  }) => {
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();

  const goToMuseumPage = () => {
    history.push('/museums/' + uuid.toString());
  }

  const clickHandler = () => {
    if (items.length > 0) {
      setExpanded(prev => !prev);
    } else {
      goToMuseumPage();
    }
  };

  return (
    <Container>
      <Item onClick={clickHandler}>
        <ArrowContainer>
          {items.length > 0 ? (expanded ? <ArrowDropDown/> : <ArrowRight/>) : <ArrowRight style={{opacity: 0}}/>}
        </ArrowContainer>
        <ImageContainer>
          {!!image ? <img src={image + '?dimension=250x250'} loading="lazy" alt={title || name}/> : <PlaceholderImage><PlaceholderIcon/></PlaceholderImage>}
        </ImageContainer>
        <ContentContainer>
          <Title>{title || name}</Title>
          <DescriptionContainer>
            {!!distance ? <><GpsFixed/><Distance>{formatDistance(distance)}</Distance></> : null}
            {!!description ? <><AccountTree/><Description>{description}</Description></> : null}
          </DescriptionContainer>
        </ContentContainer>
          <Button label={'Info'} arrow onClick={goToMuseumPage}/>
      </Item>
      <Children>
        <Collapse in={expanded}>
          {expanded && items.map(item => <ListItem key={item.uuid + '-' + name} {...item}/>)}
        </Collapse>
      </Children>
    </Container>
  )
};

export default React.memo(ListItem);
