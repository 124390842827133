import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import React, { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as WikipediaIcon } from "../../assets/images/see_also_icons/wikipedia.svg";
import { ReactComponent as SNLIcon } from "../../assets/images/see_also_icons/snl.svg";
import LinkIcon from "@material-ui/icons/Link";
import { Store } from "../../Store";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import props from "ramda/src/props";
import clsx from "clsx";
import { translate } from "../../hooks/useFallbackTrans";
import InfoLineLabel from "./components/InfoLineLabel";
import InfoLineLink from "./components/InfoLineLink";
import InfoLineText from "./components/InfoLineText";
import styled from "styled-components";
import InfoElement from "./InfoElement";

const smallScreenWidth = 1200;

const useStyles = makeStyles({
	linkIcon: {
		color: "#999",
		marginRight: "9px",
		width: "22px",
		height: "22px",
	},
});

const Test = styled.div`
	display: flex;
`;

const Container = styled.div`
	grid-area: section;
	//column-count: 2;
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
	//width: 800px;

	@media only screen and (min-width: ${smallScreenWidth}px) {
		margin-left: 20px;
		flex-wrap: wrap;
		max-height: 500px;
		margin-bottom: 0;
	}

	//overflow: hidden;
	//grid-column-gap: 85px;
	//grid-row-gap: 39px;
	//grid-auto-flow: column;
	//grid-template-columns: repeat(2, 1fr);
	//grid-template-rows: repeat(3, min-content);
`;

const InfoSection = ({
	categories,
	coordinates,
	description,
	digitalMuseumId,
	establishment,
	visit,
	includedIn,
	consistingOf,
	isWide,
	links,
	visitAddress,
}) => {
	const { state, dispatch } = useContext(Store);
	const { t } = useTranslation("common");
	const classes = useStyles({ isWide });
	const history = useHistory();

	const buildElementArray = () => {
		const outputArr = [];

		const buildInfoText = (i18key, text) => {
			if (text) {
				const headerText = t(i18key);
				outputArr.push({ headerText, text: text });
			}
		};

		const buildInfoLink = (i18key, text, url) => {
			if (text) {
				const headerText = t(i18key);
				outputArr.push({ headerText, text: text, url: url });
			}
		};

		const buildInfoList = (i18Key, arr, objKey, doTranslate = false) => {
			//console.log(arr);
			if (arr && arr.length > 0) {
				const headerText = t(i18Key, { count: categories.length });
				const infoList = arr.map((arrElement, c_idx) => {
					//console.log(arrElement);
					return doTranslate
						? translate(arrElement[objKey], state.settings.locale)
						: arrElement;
				});
				outputArr.push({ headerText, infoList });
				//console.log("outputArr: ", outputArr);
			}
		};

		const buildInfoListConsistingOf = (i18Key, arr) => {
			if (arr && arr.length > 0) {
				const headerText = t(i18Key, { count: categories.length });
				const buttonList = arr.map((arrElement, c_idx) => {
					//console.log(arrElement);
					return {
						text: translate(arrElement.name, state.settings.locale),
						func: () => {
							history.push({ pathname: `/museums/${arrElement.uuid}` });
						},
					};
				});
				outputArr.push({ headerText, buttonList });
				//console.log("outputArr: ", outputArr);
			}
		};

		const buildInfoListTypes = (i18Key, arr) => {
			if (arr && arr.length > 0) {
				const headerText = t(i18Key, { count: categories.length });
				const infoList = [];
				arr.forEach(element => {
					element.subCategories.forEach(subcat => {
						infoList.push(
							translate(subcat.displayValue, state.settings.locale),
						);
					});
				});
				outputArr.push({ headerText, infoList });
			}
		};

		const buildInfoListCategories = (i18Key, arr) => {
			if (arr && arr.length > 0) {
				const headerText = t(i18Key, { count: categories.length });
				const buttonList = arr.map((arrElement, c_idx) => {
					//console.log(arrElement);
					return {
						text: translate(
							arrElement.category.displayValue,
							state.settings.locale,
						),
						func: () => gotoMapAndFilter(arrElement.category.value),
					};
				});
				outputArr.push({ headerText, buttonList });
				//console.log("outputArr: ", outputArr);
			}
		};

		const timeStringConverter = timestr => {
			let timestrTmp = timestr.replace(/\?/g, "");
			timestrTmp = timestrTmp.toUpperCase();
			if (
				timestrTmp.includes("-Y") ||
				timestrTmp.includes("-C") ||
				timestrTmp.includes("-X")
			) {
				timestrTmp = timestrTmp.replace(/(-Y)|(-C)|(-X)/g, "");
				return `${timestrTmp} (YYYY)`;
			}

			if (timestrTmp.includes("-M")) {
				timestrTmp = timestrTmp.replace(/-M/g, "");
				return `${timestrTmp.substring(4)}.${timestrTmp.substring(
					0,
					4,
				)} (MM.YYYY)`;
			}

			if (timestrTmp.includes("-D")) {
				timestrTmp = timestrTmp.replace(/-D/g, "");
				return `${timestrTmp.substring(6)}.${timestrTmp.substring(
					4,
					6,
				)}.${timestrTmp.substring(0, 4)} (DD.MM.YYYY)`;
			}
		};

		// type:
		buildInfoListTypes("plurals.type", categories);

		// address:
		//console.log("visitAddress: ", visitAddress);
		buildInfoList("general.address", visitAddress.split(","));

		// navigation:
		buildInfoLink(
			"detailPage.directions",
			t("detailPage.showDirections"),
			coordinates.dirUrl,
		);

		// categories:
		buildInfoListCategories("plurals.category", categories);

		// establisment:
		buildInfoText(
			"general.established",
			timeStringConverter(establishment) /*.replace(/-Y/, "")*/,
		);

		// consisting of:
		if (consistingOf) {
			buildInfoListConsistingOf(
				"general.consistingOf",
				consistingOf.filter(el => !!el),
			);
		}
		return outputArr;
	};

	const gotoMapAndFilter = value => {
		dispatch({
			type: "SET_SETTINGS_FILTERSCATEGORIES",
			payload: [value],
		});
		history.push({ pathname: "/museums" });
	};

	const seeAlsoFormatting = link => {
		if (link.includes("snl.no")) {
			return (
				<>
					<SNLIcon className={classes.linkIcon} />{" "}
					{`${t("general.entryOn")} Store Norske Leksikon`}
				</>
			);
		} else if (link.includes("no.wikipedia.org")) {
			return (
				<>
					<WikipediaIcon className={classes.linkIcon} />{" "}
					{`${t("general.entryOn")} Wikipedia (no)`}
				</>
			);
		} else if (link.includes("nn.wikipedia.org")) {
			return (
				<>
					<WikipediaIcon className={classes.linkIcon} />{" "}
					{`${t("general.entryOn")} Wikipedia (nn)`}
				</>
			);
		} else if (link.includes("sv.wikipedia.org")) {
			return (
				<>
					<WikipediaIcon className={classes.linkIcon} />{" "}
					{`${t("general.entryOn")} Wikipedia (sv)`}
				</>
			);
		} else if (link.includes("wikipedia.org")) {
			return (
				<>
					<WikipediaIcon className={classes.linkIcon} />{" "}
					{`${t("general.entryOn")} Wikipedia (en)`}
				</>
			);
		} else if (link.includes("bibsys.no")) {
			return (
				<>
					<LinkIcon className={classes.linkIcon} />{" "}
					{`${t("general.entryOn")} Bibsys`}
				</>
			);
		} else {
			return (
				<>
					<LinkIcon className={classes.linkIcon} />{" "}
					{<span className={classes.otherLink}>{link}</span>}
				</>
			);
		}
	};

	buildElementArray();

	return (
		<Container>
			{buildElementArray().map((el, idx) => (
				<InfoElement
					key={"ie_" + idx}
					headerText={el.headerText}
					text={el.text ? el.text : undefined}
					infoList={el.infoList ? el.infoList : []}
					url={el.url ? el.url : undefined}
					buttonList={el.buttonList}
				/>
			))}
		</Container>
	);
};

export default InfoSection;
