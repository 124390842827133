import {Api} from "./Api";



export class KnService extends Api {
  LIST_UUID = "eb04bd82-30cd-4f7c-9f71-51f009c3270b";

  getList = async () => {
    const museumService = new museumService();
    const [knList, musList] = await Promise.all([this.get(`/list/${this.LIST_UUID}`), museumService.getAll()]);
    return this.parseMuseumsList(knList, musList && 'museums' in musList ? musList.museums : []);
  };
  search = async (query, locale) => {
    // not all language codes are supported in kulturNav search:
    let _locale = "no";
    if (locale === "nb" || locale === "se"){
      _locale = "no";
    }
    const res = await this.get(`/search/nativeText:${query}*,entity.list:${this.LIST_UUID}/0/100?lang=${_locale};idonly`);
    return res;
  }
  parseMuseumsList = (list, musList = []) => {
    const _list = [];
    list.forEach(item => {
      let _item = this.parseMuseum(item);

      // Do not add Museumsguiden to list of museums
      if (_item.uuid === "eb04bd82-30cd-4f7c-9f71-51f009c3270b") {
        return;
      }
      _list.push(_item);
    });
    _list.forEach( item => {
      item.image = this.parseImage(item, musList);
    });
    return _list;
  };
  parseMuseum = (item) => {
    if (typeof item !== 'object') {
      return {};
    }
    let _item = {};
    _item.uuid = 'uuid' in item && item.uuid ? item.uuid : null;
    _item.position = this.parseLocation(item);
    _item.identifiers = this.parseIdentifiers(item);
    _item.name = this.parseName(item);
    _item.categories = this.parseCategories(item);
    _item.description = this.parseDescription(item);
    _item.hasParts = this.parseHasParts(item);
    _item.isPartOf = this.parseIsPartOf(item);
    _item.establishment = this.parseEstablishment(item);
    _item.url = this.parseUrl(item);
    _item.seeAlso = this.parseSeeAlso(item);
    _item.visitAddress = this.parseVisitAddress(item);
    _item.county = this.parseCounty(item);
    //_item.museumType = this.parseMuseumType(item);
    //_item.distance = this.calcDistance(item);


    return _item;
  };
  parseLocation = (item) => {
    try {
      const lat = item.properties['entity.latitude'][0].value;
      const lng = item.properties['entity.longitude'][0].value;
      if (!lng || !lng) {
        throw Error("Can't parse latitude or longitude");
      }
      return { lat, lng };
    } catch (err) {
      //console.log("Error parsing location from. Item: ", item);
      return { lat: null, lng: null };
    }
  };
  parseCategories = item => {
    let categories = [];
    try {
      item.properties['organization.focus'].forEach((_item) => typeof _item.value === 'string' && categories.push(_item.value));
    } catch (err) {

    }
    return categories;
  };
  parseImage = (item, musList) => {
    let _img = '';
    try{
      let ident = item.identifiers.find( x => x.system === 'DigitaltMuseum');
      for (let x = 0; x < musList.length; x++){
         if (musList[x].identifiers.includes(`dm-${(ident.id).toLowerCase()}`)){
           _img = typeof musList[x].media === 'string' && musList[x].media.length > 3 ? 'https://' + musList[x].media : '';
           break;
         }
      }
      return _img;
    } catch( err){
      return _img;
    }
  }
  parseIdentifiers = item => {
    let idArray = [];
    try {
      const alternative_ids = item.properties['entity.alternativeId'];
      //console.log(alternative_ids);
      if (typeof alternative_ids === 'undefined' || !Array.isArray(alternative_ids)) {
        throw Error("No identifiers for item");
      }
      alternative_ids.forEach(x => {
        try {
          let id = x.value.properties['alternativeId.id'][0].value;
          let system = x.value.properties['alternativeId.system'][0].value;
          if (id || system) {
            idArray.push({ id: id ? id : null, system: system ? system : null });
          }
        } catch (err) {
          //console.log("Error parsing identifier item. Err: ", err);
          idArray.push({ id: null, system: null })
        }
      })
    } catch (err) {
      //console.log("Error parsing identifiers. Error: ", err);
    } finally {
      return idArray;
    }
  };
  parseName = item => {
    let name = {};
    try {
      let _name = item.properties['entity.name'][0].value;
      if (!_name || Object.entries(_name).length === 0) {
        throw Error("Can't parse name");
      }
      name = _name;
    } catch (err) {
      //console.log("Error parsing name: Error: ", err);
    } finally {
      return name;
    }
  };
  parseDescription = item => {
    let description = "";
    try {
      // 'properties', 'entity.description', '0', 'value', 'no'
      let _description = item.properties["entity.description"][0].value;
      if (!_description) {
        throw Error("Can't parse description");
      }
      description = _description;
    } catch (err) {
      //console.error("Error parsing description: ", err);
    } finally {
      return description;
    }
  };


  parseHasParts = item => {
    let hasParts = [];
    try {
      // "properties", "organization.hasPart"

      let _hasParts = item.properties["organization.hasPart"];
      if (!_hasParts || _hasParts.length === 0) {
        throw Error("Can't parse hasParts");
      }
      hasParts = _hasParts;
    } catch (err) {

      //console.error("Error parsing hasParts: ", err);

    } finally {
      return hasParts;
    }
  };



  parseIsPartOf = item => {
    let isPartOf = "";
    try {
      //['properties', 'organization.isPartOf', '0', 'value'],
      let _isPartOf = item.properties['organization.isPartOf'][0].value;

      if (!_isPartOf) {
        throw Error("Can't parse isPartOf");
      }
      isPartOf = _isPartOf;
    } catch (err) {
      //console.error("Error parsing isPartOf: ", err);
    } finally {
      return isPartOf;
    }
  };


  parseEstablishment = item => {
    let establishment = "";

    /*
    'properties','association.establishment', '0', 'value','properties',
    'event.time', '0', 'value',
    */

    try {
      let _establishment = item.properties['association.establishment']
      [0].value.properties["event.time"][0].value;
      if (!_establishment) {
        throw Error("Can't parse establishment");
      }
      establishment = _establishment;
    } catch (err) {
      //console.error("Error parsing establisment: ", err);
    } finally {
      return establishment;
    }
  };


  parseUrl = item => {
    let url = "";

    /*
    'properties', 'organization.url', '0', 'value'
    */

    try {
      let _url = item.properties['organization.url'][0].value;
      if (!_url) {
        throw Error("Can't parse url");
      }
      url = _url;
    } catch (err) {
      //console.error("Error parsing url: ", err);
    } finally {
      return url;
    }
  };

  parseSeeAlso = item => {
    let seeAlso = [];
    //'properties', 'superconcept.seeAlso'

    try {

      let _seeAlso = item.properties['superconcept.seeAlso'];
      if (!_seeAlso || Object.entries(_seeAlso).length === 0) {
        throw Error("Can't parse seeAlso");
      }

      _seeAlso = _seeAlso.map(el => el.value);

      seeAlso = _seeAlso;
    } catch (err) {

      //console.error("Error parsing seeAlso: ", err);

    } finally {
      return seeAlso;
    }

  };


  parseVisitAddress = item => {
    let visitAddress = "";

    /*
    'properties', 'organization.placeReference', '0', 'value', "properties", "placeReference.placeString", "0", "value"
     contains localization
    */

    try {
      let _visitAddress = item.properties['organization.placeReference'][0].value.properties["placeReference.placeString"][0].value;
      if (!_visitAddress) {
        throw Error("Can't parse visitAddress");
      }
      visitAddress = _visitAddress;
    } catch (err) {
      //console.error("Error parsing url: ", err);
    } finally {
      return visitAddress;
    }
  };


  parseCounty = item => {
    let county = "";
    /*
      properties  organization.placeReference  0  value properties  placeReference.place 0 value

    */
    try {
      let _county = item.properties['organization.placeReference'][0].value.properties["placeReference.place"][0].value;
      if (!_county) {
        throw Error("Can't parse county");
      }
      county = _county;
    } catch (err) {
      //console.error("Error parsing url: ", err);
    } finally {
      return county;
    }
  };


  /* CATEGORIES LIST */


  getCategoriesList = async () => {
    const categories = await this.get("/b881deb4-b9a9-48b3-bf0c-f311ad1e2763");
    return this.parseCategoriesList(categories);
  };

  parseCategoriesList = async (categories) => {

    const categoriesTmp = categories.properties["list.member"];
    const categoriesWithSub = await this.getSubCategories(categoriesTmp);

    return categoriesWithSub;
  };

  getSubCategory = async (mainCategoryUuid) => {
    return await this.get(`/${mainCategoryUuid}`);
  };

  /** 
   *  Fetches all subcategories and adds them to the corresponding main categories
  */
  getSubCategories = async (categories) => {
    const categoriesList = {};
    
    await Promise.all(categories.map(async category => {
      const _categoryData = await this.getSubCategory(category.value);
      const parsedSubcategories = this.parseSubCategory(_categoryData);
      category.subCategories = {};
      category.alternativeName = this.parseCategoryAlternativeName(_categoryData);
      parsedSubcategories.forEach(_subCategory => {

        category.subCategories[_subCategory.value] = _subCategory;
      });

      categoriesList[category.value] = category;

      return category;
    }))

    return categoriesList;
  }

  parseSubCategory = (categoryData) => {
    return categoryData.properties["list.member"];
  };

  parseCategoryAlternativeName = (categoryData) => {
    return categoryData.properties["entity.alternativeName"][0].value;
  };






  // COUNTIES

  /*
    museumsliste: 
    properties  organization.placeReference  0  value properties  placeReference.place 0 value
  
    ex oslo "20176e1a-b050-4025-bd56-116c7fa24828"
  */

  getCountiesList = async () => {
    const counties = await this.get("/list/044fbd0f-69f2-4aba-9f33-7b7c1aea4fb0");
    const parsedCounties = this.parseCountiesList(counties);
    return parsedCounties;

  }


  parseCountiesList = (countiesData) => {
    /* 
       7 Oslo,                      "20176e1a-b050-4025-bd56-116c7fa24828"
       8 Rogaland,                  "13db8674-97ef-4b85-bef0-4b3aa08dbe98"
       4 Møre og Romsdal ,          "d29a940a-7b98-46a3-991d-8eeb66685c0d"
       6 Nordland,                  "b4308fdf-5071-4f9f-ace1-28410050b729"
       14 Viken,                    "21309e80-a007-4067-bd96-42d01353c807"
       3 Innlandet,                 "b16be315-0f5c-4dc5-b1e4-b6c973634756"
       12 Vestfold og Telemark,     "aa60b652-7e00-4ce9-b8f1-afedaad9f54e"
       1 Agder,                     "e1de5a9f-78d7-4de5-a500-b9387e44baa9"
       13 Vestland,                 "0984edfa-1fec-4bb9-97c2-28d13778abc5"
       11 Trønderlag,               "126c716f-f597-415f-b905-1921384b9d94"
       10 Troms og Finnmark.        "185be2d1-f117-4883-bb32-abaa4fbae353"
       */

    const validCountiesUuids = [
      "20176e1a-b050-4025-bd56-116c7fa24828",
      "13db8674-97ef-4b85-bef0-4b3aa08dbe98",
      "d29a940a-7b98-46a3-991d-8eeb66685c0d",
      "b4308fdf-5071-4f9f-ace1-28410050b729",
      "21309e80-a007-4067-bd96-42d01353c807",
      "b16be315-0f5c-4dc5-b1e4-b6c973634756",
      "aa60b652-7e00-4ce9-b8f1-afedaad9f54e",
      "e1de5a9f-78d7-4de5-a500-b9387e44baa9",
      "0984edfa-1fec-4bb9-97c2-28d13778abc5",
      "126c716f-f597-415f-b905-1921384b9d94",
      "185be2d1-f117-4883-bb32-abaa4fbae353",
    ];

    const countiesList = validCountiesUuids.map(countyUuid => {
      const countyEntry = countiesData.find(county => {
        return county.uuid === countyUuid
      });

      return { uuid: countyEntry.uuid, name: countyEntry.properties["entity.name"][0].value }
    });

    return countiesList;
  }


}




