import * as R from "ramda";
import React, { useState, useContext, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Drawer from "../components/Drawer/drawer";
import Header from "../components/Header/header";
import Footer from "../components/Footer/footer";
import StaticMap from "../components/StaticMap/staticMap";
import ShareButtons from "../components/ShareButtons/shareButtons";
import SendEmail from "../components/SendEmail/sendEmail";
import useWindowDimensions from "../hooks/useWindowDimensions";
import useDefaultMuseumImage from "../hooks/useDefaultMuseumImage";
import InfoSection from "../components/InfoSection/infoSection";
import { Card, IconButton, CardContent, Button, ClickAwayListener } from "@material-ui/core";
import { Store } from "../Store";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Settings } from "../config/Settings";
import FeedbackIcon from "@material-ui/icons/Feedback";
import { getDigitaltMuseumId } from "../helpers/getMuseumIds";
import useFallbackTrans, { translate } from "../hooks/useFallbackTrans";
import Carousel from "../components/Carousel/carousel";
import styled from "styled-components";
import {
	text_sm,
	text_base,
	text_lg,
	text_2xl,
	text_2xl_bold,
	text_5xl_bold,
	text_lg_bold,
	text_xs,
} from "../style/styles";
import PLACEHOLDER_IMG from "../assets/images/img_placeholder.png";
import { Launch } from "@material-ui/icons";
import Link from "@material-ui/icons/Link";

const smallScreenWidth = 1024;

const useStyles = makeStyles({
	mapContainer: {
		//marginTop: props => !props.isWide && "50px",
		maxWidth: "460px",
	},
});

const OuterContainer = styled.div`
	display: flex;
	justify-content: center;
	position: relative;

	@media only screen and (min-width: ${smallScreenWidth}px) {
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 60vh;
			min-height: 600px;
			background-image: url(${props =>
		props.img !== PLACEHOLDER_IMG
			? props.img + "?dimension=max"
			: PLACEHOLDER_IMG});
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			z-index: 0;
		}
	}
`;

const Container = styled.div`
	box-sizing: border-box;
	display: flex;
	background: #fafafa;
	width: 100vw;
	z-index: 1;

	padding: 82px 20px;

	@media only screen and (min-width: ${smallScreenWidth}px) {
		border-radius: 24px 24px 0 0;
		width: 80vw;
		max-width: 1200px;
		padding: 82px 126px;

		//box-shadow: 0px 0px 10px #00000085;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
		margin-top: 135px;
	}
`;

const Content = styled.main`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const MuseumImage = styled.img`
	border-radius: 50%;
	width: 200px;
	height: 200px;
	object-fit: cover;
	//box-shadow: 0px 0px 10px #00000085;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
	margin-bottom: 30px;

	@media only screen and (min-width: ${smallScreenWidth}px) {
		//display: none;
	}
`;

const Title = styled.h1`
	//styleName: text-5xl--bold;
	${text_2xl_bold}
	//font-family: var(--akkurat);
  //font-size: 48px;
  //font-style: normal;
  //font-weight: 700;
  //line-height: 48px;
  //letter-spacing: 0em;
  text-align: center;
	color: var(--brand-color-for-type);

	@media only screen and (min-width: ${smallScreenWidth}px) {
		${text_5xl_bold}
	}
`;

const DescriptionText = styled.p`
	margin: 15px 0;
	${text_base} // font
  text-align: left;
	color: var(--Museumssvart);

	@media only screen and (min-width: ${smallScreenWidth}px) {
		${text_2xl}
		margin: 24px 0;
	}
`;

const IncludedInText = styled.p`
	margin: 15px 0;
	//styleName: text-lg;
	${text_sm}
	letter-spacing: 0em;
	text-align: center;
	color: var(--brand-color-for-type);

	@media only screen and (min-width: ${smallScreenWidth}px) {
		${text_lg}
		margin: 24px 0;
	}
`;

const IncludedinButton = styled(Button)`
	display: inline;
	color: var(--blaze600);
	${text_sm}
	background: none;
	padding: 0;
	border: none;
	align-content: left;
	vertical-align: baseline;
	text-align: left;
	text-transform: none;
	cursor: pointer;
	@media only screen and (min-width: ${smallScreenWidth}px) {
		${text_lg}
	}
`;

const LinkButton = styled(Button)``;

const MapAndSection = styled.div`
	width: 100%;
	margin-top: 48px;
	display: grid;
	grid-template-rows: auto auto;
	grid-template-areas:
		"section"
		"map";
	gap: 29px;
	@media only screen and (min-width: ${smallScreenWidth}px) {
		grid-template-columns: 40% 60%;
		grid-template-areas: "map section";
	}
`;

const ButtonLinks = styled.div`
	display: flex;
	width: 100%;
	margin-top: 49px;
	flex-direction: column;

	@media only screen and (min-width: ${smallScreenWidth}px) {
		flex-direction: row;
	}
`;

const ButtonLink = styled(Button)`
	background-color: #fff;
	text-transform: none;
	${text_lg_bold}
	height: 69px;
	border-radius: 16px;
	&:not(:last-of-type) {
		margin-bottom: 12px;
		@media only screen and (min-width: ${smallScreenWidth}px) {
			margin-right: 12px;
		}
	}
`;

const CloseButtonContainer = styled.div`
	display: flex;
	width: 100%;
	margin-top: 48px;
	margin-bottom: 80px;
`;
const FeedbackAndSourceContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	margin-top: 10px;
	align-items: center;
`;

const EmailButton = styled(Button)`
	${text_xs};
	color: darkred;
	text-transform: none;

`;

const CloseButton = styled(Button)`
	text-transform: none;
	${text_base}
`;

const SourceLink = styled(EmailButton)`	
	margin-right: 20px;
`;

const ArrowBackIconCustom = styled(ArrowBackIcon)``;

const DetailsPage = () => {
	const { state, dispatch } = useContext(Store);
	const { t } = useTranslation("common");
	const history = useHistory();
	const path = R.path(["location", "pathname"], history);
	const { width } = useWindowDimensions();
	const isWide = width > smallScreenWidth;

	const appUrl = Settings.APP_URL + path;
	const { museumId } = useParams();
	//const museumsListData = state.data.museumsList; //useMuseumsList();
	//const [museumsList, setMuseumsList] = useState([]); //R.propOr([], 'museumsList', museumsListData);
	const museumsList = state.data.museumsList;
	const museum =
		!R.isNil(museumsList) &&
		!R.isEmpty(museumsList) &&
		museumsList.find(m => R.prop("uuid", m) === museumId);

	const [open, setOpen] = useState(false);
	const [isSendEmailVisible, setSendEmailVisible] = useState(false);

	const nameOfMuseum = useFallbackTrans(museum.name);
	const digitalMuseumId = getDigitaltMuseumId(museum);

	const lat = museum.position ? museum.position.lat : null;
	const lng = museum.position ? museum.position.lng : null;

	const descText = useFallbackTrans(museum.description);

	const setCurrentPage = () => {
		//console.log("state.user.currentPage: ", state.user.currentPage);
		if (state.user.currentPage === "/museums/details") {
			return;
		}
		dispatch({
			type: "SET_USER_CURRENTPAGE",
			payload: "/museums/details",
		});
	};
	setCurrentPage();

	const havePos = useCallback(mus => {
		try {
			const { lat, lng } = mus.position;
			return lat && lng ? true : false;
		} catch (err) {
			return false;
		}
	}, []);

	const parseCoordinates = (lat, lng) => {
		if (!lat || !lng) return;
		const parseLatOrLng = latOrLng => {
			const degrees = Math.floor(latOrLng);
			let tmpCalc = (latOrLng - degrees) * 60;
			const minutes = Math.floor(tmpCalc);
			let seconds = (tmpCalc - minutes) * 60;
			return `${degrees}°${minutes}'${seconds.toFixed(3)}"`;
		};

		//https://www.google.com/maps/place/
		const parsedLat = parseLatOrLng(lat);
		const parsedLng = parseLatOrLng(lng);
		const coordinateStr = `${parsedLat} N, ${parsedLng} E`;
		const coordinateLink = `https://maps.google.com/?q=${lat},${lng}`
		console.log(`${state.user.lat},${state.user.lng}`)
		const userCoords = state.user.lat && state.user.lng ? `${state.user.lat},${state.user.lng}` : "";
		const dirUrl = `https://www.google.com/maps/dir/${userCoords}/${lat},${lng}/@${lat},${lng},13z`; //`https://www.google.com/maps/dir/${state.user.lat},${state.user.lng}/${lat},${lng}/@${lat},${lng},13z`;
		return { str: coordinateStr, url: coordinateLink, dirUrl: dirUrl };
	};

	const coordinates = parseCoordinates(lat, lng);
	const establishment = museum.establishment;
	const description = useFallbackTrans(museum.description);
	const links = museum.seeAlso ? museum.seeAlso : [];
	const visit = museum.url;
	const isPartOfId = museum.isPartOf;
	const visitAddress = useFallbackTrans(museum.visitAddress);

	const getSubCategories = () => {
		if (!museum.categories) {
			return;
		}
		const _categories = museum.categories.map(element => {
			try {
				const foundCategory = Object.values(state.data.categoriesList).find(
					findElement => {
						return findElement.subCategories[element];
					},
				);
				return {
					category: foundCategory,
					subCategory: foundCategory.subCategories[element],
				};
			} catch (err) {
				console.warn("Error: Could not find category ", element, ", ", err);
			}
		});
		//console.log("categories pre:", _categories);
		// logikk for å lage hierarkisk liste
		const categories = {};
		// filter(el=>el) removes undefined elements
		_categories
			.filter(el => el)
			.forEach(element => {
				if (!categories[element.category.uuid]) {
					categories[element.category.uuid] = {
						category: element.category,
						subCategories: [],
					};
				}
				categories[element.category.uuid].subCategories.push(
					element.subCategory,
				);
			});

		//console.log("===>categories: ", Object.values(categories));
		return Object.values(categories);

		//return _categories;
	};

	const categories = getSubCategories();

	const isPartOf =
		!R.isNil(museumsList) &&
		!R.isEmpty(museumsList) &&
		museumsList.find(m => R.prop("uuid", m) === isPartOfId);

	const getAllParts = () => {
		const tmpHasParts = museum.hasParts;
		if (R.isNil(tmpHasParts) || R.isEmpty(tmpHasParts)) {
			return;
		}

		return tmpHasParts.map(part => {
			const hasPartId = R.path(["value"], part);

			const hasPart =
				!R.isNil(museumsList) &&
				!R.isEmpty(museumsList) &&
				museumsList.find(m => R.prop("uuid", m) === hasPartId);

			return hasPart;
		});
	};
	const hasPart = getAllParts();

	const handleDrawerClose = () => {
		setOpen(false);
	};
	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const imgHeight = width > 400 ? 400 : 200;
	const image = museum.image || PLACEHOLDER_IMG; //useDefaultMuseumImage(digitalMuseumId) || PLACEHOLDER_IMG;

	const classes = useStyles({ image, isWide, imgHeight });

	const isInfoExist = !R.isNil(
		description || establishment || visit || isPartOf || hasPart,
	);
	const isStaticMapExist = !R.isNil(lat) && !R.isNil(lng);

	const handleSuggest = () => {
		setSendEmailVisible(!isSendEmailVisible);
	};

	/*
  useEffect(() => {
	console.count("digitalmuseum useEffect");
	if (typeof digitalMuseumId !== 'string') {
	  return;
	}
	const kpService = new KpService();
	kpService.getOwnerIdByIdentifier(digitalMuseumId);



  }, [digitalMuseumId]);


  useEffect(() => {

	setMuseumsList(state.data.museumsList);
  }, [state.data.museumsList])
  */

	return (
		<OuterContainer img={image}>
			<Helmet>
				<title>Museumsguiden.no - {nameOfMuseum}</title>
				<meta property="og:title" content={nameOfMuseum} />
				<meta property="og:description" content={description} />
				<meta property="og:type" content="Article" />
				<meta property="og:url" content={`https://www.museumsguiden.no/museums/${museum.uuid}`} />
				<meta property="og:image" content={image === PLACEHOLDER_IMG ? `https://www.museumsguiden.no${image}` : `${image}"?dimension=800x800"`} />
			</Helmet>


			<Container>
				<Content>
					<MuseumImage src={image /*? image : PLACEHOLDER_IMG*/} />

					<Title>{nameOfMuseum}</Title>
					{isPartOf?.name && (
						<IncludedInText>
							{`${t("general.includedIn")} `}
							<IncludedinButton
								onClick={() => {
									history.push({ pathname: `/museums/${isPartOf.uuid}` });
								}}
							>
								{translate(isPartOf.name, state.settings.locale)}
							</IncludedinButton>
						</IncludedInText>
					)}
					<DescriptionText>{description}</DescriptionText>

					<MapAndSection>
						{isStaticMapExist && (
							<a
								href={`https://maps.google.com/?q=${lat},${lng}`}
								target="_blank"
							>
								<div className={classes.mapContainer}>
									<StaticMap lat={lat} lng={lng} isWide={isWide} />
								</div>
							</a>
						)}
						{isInfoExist && (
							<InfoSection
								categories={categories}
								consistingOf={hasPart}
								coordinates={coordinates}
								description={description}
								digitalMuseumId={digitalMuseumId}
								establishment={establishment}
								includedIn={isPartOf}
								isWide={isWide}
								links={links}
								visit={visit}
								visitAddress={visitAddress}
							/>
						)}
					</MapAndSection>
					<ButtonLinks>
						{!!visit && (
							<ButtonLink
								variant="contained"
								endIcon={<Launch />}
								fullWidth
								href={visit}
								target="_blank"
							>
								{t("detailPage.museumLinkText")}
							</ButtonLink>
						)}
						{!!digitalMuseumId && (
							<ButtonLink
								variant="contained"
								endIcon={<Launch />}
								fullWidth
								href={
									/[^0-9]/.test(digitalMuseumId)
										? `https://www.digitalmuseum.no/owners/${digitalMuseumId}`
										: `https://www.digitalmuseum.no/${digitalMuseumId}`
								}
								target="_blank"
							>
								{t("detailPage.dmLinkText")}
							</ButtonLink>
						)}
					</ButtonLinks>
					<FeedbackAndSourceContainer>
						<SourceLink
							startIcon={<Link />}
							href={`https://kulturnav.org/${museumId}`}
							target="_blank">
							{t("detailPage.source")}
						</SourceLink>
						<EmailButton
							startIcon={<FeedbackIcon />}
							onClick={() => { handleSuggest() }}>{t("detailPage.feedback")}</EmailButton>
					</FeedbackAndSourceContainer>
					{isSendEmailVisible &&
						<SendEmail setSendEmailVisible={setSendEmailVisible} title={nameOfMuseum} />
					}
					<CloseButtonContainer>
						<CloseButton
							startIcon={<ArrowBackIconCustom />}
							onClick={() => {
								history.goBack();
							}}
						>
							{t("detailPage.back")}
						</CloseButton>
					</CloseButtonContainer>

				</Content>
			</Container>
		</OuterContainer>
	);
};

export default DetailsPage;
