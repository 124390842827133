import NaturHistoriskImg from "../../assets/images/categoryPhotos/james-lee-F15yYq9aAsM-unsplash.jpg";
import NaturVitenskapImg from "../../assets/images/categoryPhotos/halacious-OgvqXGL7XO4-unsplash.jpg";
import Arkeologi from "./images/categories/arkeologi.jpg";
import Friluftsmuseum from "./images/categories/friluftsmuseum.jpg";
import KunstOgFoto from "./images/categories/kunst-og-foto.jpg";
import VitenOgOpplevelsessenter from "./images/categories/viten-og-opplevelsessenter.jpg";
import Sjofart from "./images/categories/sjofart.jpg";
import Industri from "./images/categories/industri.jpg";
import Militaer from "./images/categories/militaer-krigshistorisk.jpg";
import Musikk from "./images/categories/saso-tusar-QtgGYlug6Cw-unsplash.jpg";
import Transport from "./images/categories/transport_markus-spiske-8eDOHGxQtuI-unsplash.jpg";
import Annet from "./images/categories/annet-liar-tapi-dah-jinak-9skLz_qQLRo-unsplash.jpg";
import Kulturhistorie from "./images/categories/kulturhistorisk-ansgar-scheffold-TQWshKgaYUg-unsplash.jpg";



export const categoriesSettings = 
[
    {
      uuid: '937473bc-7628-4802-9469-201429a6e271',
      valueType: 'ENTITY_REFERENCE',
      value: 'b220e198-caf2-4bc4-9e8c-fd9147694a97',
      displayValue: {
        no: 'Arkeologiske museer',
        sv: 'Arkeologiska museer'
      },
     
      alternativeName: {
        no: 'Arkeologi',
        sv: 'Arkeologi',
        en: 'Archaeology'
      },
      img: Arkeologi,
    },
    {
      uuid: 'e5a62b5d-f70c-45ec-942e-6a6b602f0ef1',
      valueType: 'ENTITY_REFERENCE',
      value: '5e466556-5981-44b4-8414-1d6064e11a41',
      displayValue: {
        no: 'Foto- og kunstmuseer'
      },
    
      alternativeName: {
        no: 'Foto- og kunst'
      },
      img: KunstOgFoto,
    },
    {
      uuid: 'b4244ca6-f4ad-44b8-a218-755b9614621a',
      valueType: 'ENTITY_REFERENCE',
      value: 'accc763b-45a4-4ee1-8b4d-4db82e0fe052',
      displayValue: {
        no: 'Friluftsmuseer, bygdemuseer og folkemuseer'
      },
      
      alternativeName: {
        no: 'Friluftsmuseer'
      },
      img: Friluftsmuseum,

    },
    {
      uuid: '46b75d7c-180f-44eb-8882-3645ff274ec3',
      valueType: 'ENTITY_REFERENCE',
      value: '30b0608e-6680-4b7e-83db-073e85b33d52',
      displayValue: {
        no: 'Industrimuseer'
      },
      
      alternativeName: {
        no: 'Industri'
      },
      img: Industri,

    },
    {
      uuid: 'a052d4e5-7f5d-4f48-bedf-988026a5d533',
      valueType: 'ENTITY_REFERENCE',
      value: 'd47b7ae4-0b9b-47c7-816f-fe71ce2718c6',
      displayValue: {
        no: 'Kulturhistoriske museer'
      },
 
      alternativeName: {
        no: 'Kulturhistorie'
      },
      img: Kulturhistorie,
    },
    {
      uuid: 'd46f427b-1954-4bed-8ae3-c6df0a7ad43d',
      valueType: 'ENTITY_REFERENCE',
      value: '66a1147e-f0c9-4d48-a725-fa51ac232a56',
      displayValue: {
        no: 'Militær- og krigshistoriske museer'
      },

      alternativeName: {
        no: 'Militært'
      },
      img: Militaer,
    },
    {
      uuid: '5cf2ebb4-234c-4168-8186-5533b4e8ec68',
      valueType: 'ENTITY_REFERENCE',
      value: 'e325a25e-f4a4-4e84-b768-5ebe38e75af1',
      displayValue: {
        no: 'Musikk- og scenekunstmuseer'
      },

      alternativeName: {
        no: 'Musikk- og scene'
      },
      img: Musikk,
    },
    {
      uuid: '10e86387-bc4f-44ef-8b99-fa3c7f923acb',
      valueType: 'ENTITY_REFERENCE',
      value: '45a77163-d3d2-45f7-ab72-67f5d0e73131',
      displayValue: {
        no: 'Natur og naturhistoriske museer'
      },     

      alternativeName: {
        no: 'Natur'
      },
      
      img: NaturHistoriskImg,
    },
    {
      uuid: '1ee3e180-a215-4daa-a039-eae975a8d333',
      valueType: 'ENTITY_REFERENCE',
      value: '9a063b39-1a43-48b5-a5a3-a77dd0b4e0bb',
      displayValue: {
        no: 'Naturvitenskapelige og teknologiske museer'
      },
      alternativeName: {
        no: 'Teknikk',
        sv: 'Teknik',
        en: 'Technology'
      },

      img: NaturVitenskapImg,
    },
    {
      uuid: 'e605952c-3f27-48b8-889a-2690c28445bc',
      valueType: 'ENTITY_REFERENCE',
      value: '057bc8b8-daef-49d8-8ae9-941ce9a78ec6',
      displayValue: {
        no: 'Sjøfart og maritime museer',
        sv: 'Sjöfarts- och maritima museer'
      },

      alternativeName: {
        no: 'Kyst og hav'
      },
      img: Sjofart,
    },
    {
      uuid: '7acec157-13e8-4f8d-8da2-f18939636d3b',
      valueType: 'ENTITY_REFERENCE',
      value: 'b7e3318d-e793-4cfd-9bdd-dd6fb756ab96',
      displayValue: {
        no: 'Transportmuseer'
      },
  
      alternativeName: {
        no: 'Transport'
      },
      img: Transport,
    },
    {
      uuid: '76dcab9f-e13a-4a28-bf18-b95d49d369e6',
      valueType: 'ENTITY_REFERENCE',
      value: '5eb57a08-0936-43c4-b2ad-8fe8fc0d375f',
      displayValue: {
        no: 'Viten- og opplevelsessenter'
      },
     
      alternativeName: {
        no: 'Senter'
      },
      img: VitenOgOpplevelsessenter,
    },
    {
      uuid: '337932f9-5468-45ad-846b-8378df1e4ef4',
      valueType: 'ENTITY_REFERENCE',
      value: 'a2ce370b-e955-4a1d-bbfb-3f1a375cdbd2',
      displayValue: {
        no: 'Annet',
        sv: 'Annat'
      },
      
      alternativeName: {
        no: 'Annet'
      },
      img: Annet
    }
    
  ];