import {
	Backdrop,
	Button,
	ButtonBase,
	CircularProgress,
	makeStyles,
} from "@material-ui/core";
import React, { useContext, useState, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer/footer";
import Header from "../components/Header/header";
import { Store } from "../Store";
import ReadMoreButton from "../components/ReadMoreButton/readMoreButton";
import { sortCategories, sortCounties } from "../helpers/filterHelpers";
import Drawer from "../components/Drawer/drawer";
import { useTranslation } from "react-i18next";
import { ArrowForward } from "@material-ui/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { translate } from "../hooks/useFallbackTrans";
import CategoryCard from "../components/CategoryCard/categoryCard";
import styled from "styled-components";
import { text_xl, text_3xl_bold, text_xl_bold, text_lg } from "../style/styles";

const MIN_WIDTH = "800px";

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
}));

const Container = styled.div`
	background-color: var(--Museumsbakgrunn);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	//flex: "1",
	min-height: 100vh;
	@media only screen and (min-width: 800px) {
		margin-left: 80px;
	}
`;

const MainContainer = styled.main`
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: center;
	//background-color: #EDEDED;
	min-height: fit-content;
	flex: 1 1;
	margin: 32px auto;
	margin-bottom: 80px;
	width: 100%;
`;

const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-self: center;
	margin-bottom: 80px;
	width: 80%;

	@media only screen and (min-width: 800px) {
		//margin-left: 80px;
		align-self: flex-start;
		width: unset;
	}
`;

const Title = styled.h1`
	${text_3xl_bold}
	//styleName: text-3xl--bold;
    //font-family: var(--akkurat);
    //font-size: 30px;    
    //font-weight: 700;
    //line-height: 36px;
    margin-bottom: 10px;

	@media only screen and (min-width: ${MIN_WIDTH}) {
		font-size: 48px;
		line-height: 48px;
		margin-bottom: 12px;
	}
`;

const SubTitle = styled.p`
    ${text_lg}    

    @media only screen and (min-width: ${MIN_WIDTH}){        
        ${text_xl}        
    }  
`;

const CardsContainer = styled.div`
	width: 100%;
	display: grid;
	grid-gap: 32px;
	grid-template-columns: auto;
	z-index: 1;

	@media only screen and (min-width: ${MIN_WIDTH}) {
		display: grid;
        place-items: baseline;
		grid-template-columns: repeat(auto-fit, 302px);
	}
`;

const CardClickTarget = styled(ButtonBase)`
	position: relative;
	user-select: none;
	cursor: pointer;
	transition: transform 0.3s ease-in-out;
	&:hover {
		transform: scale(1.05);
	}

	/* &::after{
            position: absolute;
            content: "";
            background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.0));
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 24px 24px 0 0;

        } */

	&:focus {
		&::after {
			position: absolute;
			content: "";
			background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 24px 24px 0 0;
		}
	}
`;

const CategoriesPage = ({ isCategories = true }) => {
	const history = useHistory();
	const { state, dispatch } = useContext(Store);
	const [bgImage, setBgImage] = useState("");
	const [bgImageMuseum, setBgImageMuseum] = useState({ name: "", id: "" });
	const { t } = useTranslation("common");
	const categories = isCategories
		? sortCategories(Object.values(state.data.categoriesList), false)
		: sortCounties(Object.values(state.data.countiesList));
	const museumsList = useMemo(() => state.data.museumsList, [
		state.data.museumsList,
	]);
	const classes = useStyles({ bgImage: bgImage });
	const [open, setOpen] = React.useState(false);
	const backdropOpen = categories.length < 1 ? true : false;

	const setCurrentPage = () => {
		//console.log("state.user.currentPage: ", state.user.currentPage);
		if (
			(state.user.currentPage === "/categories" && isCategories) ||
			(state.user.currentPage === "/counties" && !isCategories)
		) {
			return;
		}
		dispatch({
			type: "SET_USER_CURRENTPAGE",
			payload: isCategories ? "/categories" : "/counties",
		});
	};
	setCurrentPage();
	const drawerHandler = React.useCallback(() => {
		setOpen(prev => !prev);
	}, []);

	const gotoMapAndFilter = value => {
		dispatch({
			type: isCategories
				? "SET_SETTINGS_FILTERSCATEGORIES"
				: "SET_SETTINGS_FILTERSCOUNTIES",
			payload: [value],
		});
		dispatch({
			type: isCategories
				? "SET_SETTINGS_FILTERSCOUNTIES"
				: "SET_SETTINGS_FILTERSCATEGORIES",
			payload: [],
		});
		history.push({ pathname: "/museums" });
	};

	let subTitleText = isCategories
		? t("categoriesPage.categories_subtitle")
		: t("categoriesPage.counties_subtitle");
	subTitleText = subTitleText.replace("¤", categories.length);
	subTitleText = subTitleText.replace("£", museumsList.length);

	const countByCategory = subCategories => {
		const numInCategory = museumsList.reduce((count, mElem) => {
			let tmpCount = 0;
			Object.keys(subCategories).forEach(scKey => {
				if (mElem.categories.includes(scKey)) {
					tmpCount++;
					return;
				}
			});
			count += tmpCount ? 1 : 0;
			return count;
		}, 0);
		return numInCategory;
	};

	const countByCounty = uuid => {
		const numInCounty = museumsList.reduce((count, elem) => {
			count += elem.county === uuid ? 1 : 0;
			return count;
		}, 0);

		return numInCounty;
	};

	return (
		<Container>
			<Helmet>
				<title>{isCategories ? "Kategorier" : "Fylker"} - Museumsguiden.no</title>
			</Helmet>
			{/* <Header
                title={t('general.appTitle')}
                handleDrawerOpen={drawerHandler}
                openDrawer={open}
           >

            </Header>
            {open && (
                <Drawer
                    handleDrawerClose={drawerHandler}
                    openDrawer={open}
                />
            )}*/}

			<MainContainer>				
				<TitleContainer>
					<Title>
						{isCategories ? t("general.categories") : t("general.counties")}
					</Title>

					<SubTitle>{subTitleText}</SubTitle>
				</TitleContainer>
				<CardsContainer>
					{categories &&
						categories.map(_category => {
							return (
								<CardClickTarget
									key={_category.uuid}
									onClick={() => {
										isCategories
											? gotoMapAndFilter(_category.value)
											: gotoMapAndFilter(_category.uuid);
									}}
									tabIndex={0}
								>
									<CategoryCard
										title={translate(
											isCategories ? _category.displayValue : _category.name,
											state.settings.locale,
										)}
										count={
											isCategories
												? countByCategory(_category.subCategories)
												: countByCounty(_category.uuid)
										}
										uuid={_category.uuid}
										isCategory={isCategories}
									></CategoryCard>
								</CardClickTarget>
							);
						})}
				</CardsContainer>
			</MainContainer>
			{false && <Footer></Footer>}

			<Backdrop className={classes.backdrop} open={backdropOpen}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Container>
	);
};

export default CategoriesPage;
