const Settings = require("../../config/Settings");

export class Api {
  HOST = process.env.NODE_ENV === "development" ? `http://${window.location.host}/api` : `https://${window.location.host}/api`;
  
  get = async path => {
    //console.log("\n\n\n\nHOST:"+this.HOST + " NODE_ENV: ", process.env.NODE_ENV);
    return await fetch(this.HOST + path).then(res => {
      return res.json();
    })
      .catch(err => {
        console.log("BeService error:", err);
      })
  }

}
