import NordlandImg from './images/counties/nordland_luke-stackpoole-By9u0AV2fm4-unsplash.jpg';
import OsloImg from '../../assets/images/countyPhotos/erik-odiin-V6OwHJpilAE-unsplash.jpg';
import VestLandImg from '../../assets/images/countyPhotos/lachlan-gowen-jrMsv-0o0bw-unsplash.jpg';
import VikenImg from './images/counties/viken.jpg';
import TelemarkImg from './images/counties/telemark.jpg';
import TromsOgFinnmarkImg from './images/counties/troms-finmark-ludovic-charlet-FyyQL69huIg-unsplash.jpg';
import RogalandImg from './images/counties/rogaland2.jpg';
import MoreOgRomsdalImg from './images/counties/more-og-romsdal.jpg';
import Innlandet from './images/counties/innlandet.jpg';
import TrondelagImg from './images/counties/trondelag.jpg';
import AgderImg from './images/counties/agder_mark-konig-NU5hB3RI5mY-unsplash.jpg';

export const countiesSettings = [
  {
    uuid: 'e1de5a9f-78d7-4de5-a500-b9387e44baa9',
    name: {
      no: 'Agder',
    },
    img: AgderImg,
  },
  {
    uuid: 'b16be315-0f5c-4dc5-b1e4-b6c973634756',
    name: {
      no: 'Innlandet',
    },
    img: Innlandet,
  },
  {
    uuid: 'd29a940a-7b98-46a3-991d-8eeb66685c0d',
    name: {
      no: 'Møre og Romsdal',
    },
    img: MoreOgRomsdalImg,
  },
  {
    uuid: 'b4308fdf-5071-4f9f-ace1-28410050b729',
    name: {
      no: 'Nordland',
    },
    img: NordlandImg,
  },
  {
    uuid: '20176e1a-b050-4025-bd56-116c7fa24828',
    name: {
      no: 'Oslo',
    },
    img: OsloImg,
  },
  {
    uuid: '13db8674-97ef-4b85-bef0-4b3aa08dbe98',
    name: {
      no: 'Rogaland',
    },
    img: RogalandImg,
  },
  {
    uuid: '185be2d1-f117-4883-bb32-abaa4fbae353',
    name: {
      no: 'Troms og Finnmark',
      sme: 'Romsa ja Finnmárku',
      fkv: 'Tromssa ja Finmarkku',
    },
    img: TromsOgFinnmarkImg,
  },
  {
    uuid: '126c716f-f597-415f-b905-1921384b9d94',
    name: {
      no: 'Trøndelag',
      sme: 'Trööndelage',
    },
    img: TrondelagImg,
  },
  {
    uuid: 'aa60b652-7e00-4ce9-b8f1-afedaad9f54e',
    name: {
      no: 'Vestfold og Telemark',
    },
    img: TelemarkImg,
  },
  {
    uuid: '0984edfa-1fec-4bb9-97c2-28d13778abc5',
    name: {
      no: 'Vestland',
    },
    img: VestLandImg,
  },
  {
    uuid: '21309e80-a007-4067-bd96-42d01353c807',
    name: {
      no: 'Viken',
    },
    img: VikenImg,
  },
];
