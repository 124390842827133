import React from "react";
import styled from "styled-components";


const Container = styled.div`
    height: 48px;
    width: 48px;
    border-radius: 8px;
    background-color: var(--blaze100);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
`;


const ButtonDecoration = ({ children }) => {
    return (
        <Container>{children}</Container>
    );

}

export default ButtonDecoration;